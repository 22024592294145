import { isAppProd } from 'config/env';

export const NAVIGATION = [
    {
        label: 'What’s on',
        link: '/whats-on'
    },
    {
        label: 'Sailings',
        link: '/sailings'
    },
    {
        label: 'Venue Hire',
        link: '/about/venue-hire'
    },
    {
        label: 'Volunteers Login',
        href: '/volunteer-intranet'
    },
    {
        label: 'Volunteers',
        link: '/about/get-involved'
    },
    {
        label: 'Donate',
        link: '/about/donate'
    },
    {
        label: 'Supporters & Partners',
        link: '/about/supporters-and-partners'
    }
];

export const HEADER_NAVIGATION = [
    {
        label: 'What’s On',
        link: '/whats-on',
        children: [
            {
                label: 'Events',
                link: '/whats-on/events'
            },
            {
                label: 'Exhibitions',
                link: '/whats-on/exhibitions'
            },
            {
                label: 'Families',
                link: '/whats-on/families'
            }
        ]
    },
    {
        label: 'Visit',
        link: '/visit',
        children: [
            {
                label: 'Admission',
                link: '/visit/admission'
            },
            {
                label: 'Getting here',
                link: '/visit/getting-here'
            },
            {
                label: 'Accessibility',
                link: '/visit/accessibility'
            },
            {
                label: 'Percy Vos Heritage Boat Shed',
                link: '/percy-vos-heritage-boat-shed'
            }

        ]
    },
    {
        label: 'Sailings',
        link: '/sailings',
        children: [
            {
                label: 'Ted Ashby',
                link: '/sailings/ted-ashby'
            },
            {
                label: 'Nautilus',
                link: '/sailings/nautilus'
            },
            {
                label: 'Breeze',
                link: '/sailings/breeze'
            },
            {
                label: 'SS Puke',
                link: '/sailings/ss-puke'
            },
            {
                label: 'Charters',
                link: '/charters'
            }
        ]
    },
    {
        label: 'Collections',
        link: '/collections',
        children: [
            {
                label: 'Stories and Blogs',
                link: '/collections/stories-and-blogs'
            },
            {
                label: 'Online collection',
                link: 'https://collection.maritimemuseum.co.nz/explore'
            },
            {
                label: 'Library',
                link: '/collections/library'
            }
        ]
    },
    {
        label: 'Learn',
        link: '/learn',
        children: [
            {
                label: 'Booking Enquiries',
                link: '/education-enquiry-form'
            }
        ]
    },
    {
        label: 'About',
        link: '/about',
        children: [
            {
                label: 'Get involved',
                link: '/about/get-involved'
            },
            {
                label: 'Donate',
                link: '/about/donate'
            },
            {
                label: 'Contact us',
                link: '/about/contact-us'
            },
            {
                label: 'Supporters and partners',
                link: '/about/supporters-and-partners'
            },
            {
                label: 'Work with us',
                link: '/about/work-with-us'
            },
            {
                label: 'Venue hire',
                link: '/about/venue-hire'
            }
        ]
    },
];

export const HEADER_CTA = {
    label: 'Book now',
    link: isAppProd ? 'https://tickets.maritimemuseum.co.nz' : 'https://nzmm.web.d1tix.com/events',
    trackingAttributes: {
        'data-id': 'micro_conversion',
        'data-type': 'lead',
        'data-action': 'book_now_button_click',
        'data-text': 'Book now'
    }
};

