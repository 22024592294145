export function selectFormFields(state) {
    return state.contactUsForm.fields;
}

export function selectSubmitted(state) {
    return state.contactUsForm.submitted;
}

export function selectIsBusy(state) {
    const { contactUsForm } = state;

    return contactUsForm.submitted && contactUsForm.submittedSuccessfully === undefined;
}

export function selectSubmittedSuccessfully(state) {
    return state.contactUsForm.submittedSuccessfully;
}
