/* eslint-disable require-jsdoc */

import { connect } from 'react-redux';
import EducationWhatsOnSection from './education-whats-on-section';
import { initiateEducationFetchAction } from 'app/ducks/education';
import { selectEducationList } from 'app/selectors/education';

function mapStateToProps(state) {
    return {
        educationList: selectEducationList(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchEducationList: () => dispatch(initiateEducationFetchAction())
    };
}

const VisibleEducationWhatsOnSection = connect(
    mapStateToProps,
    mapDispatchToProps
)(EducationWhatsOnSection);

export default VisibleEducationWhatsOnSection;
