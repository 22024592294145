import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

class PaginationIndication extends React.Component {
    render() {
        const { total, currentIndex, fixedWidth } = this.props;

        if (total < 2) {
            return <div />;
        }

        const totalAsString = total.toString();
        const description = `Page ${currentIndex + 1} of ${total}`;

        return (
            <div
                className={classnames('pagination-indication', { 'fixed-width': fixedWidth })}
                role="presentation"
                title={description}
                aria-label={description}
            >
                <div className="pagination-indication-number || heading-5">
                    01
                </div>
                <div className="pagination-indication-line">
                    <div
                        className="pagination-indication-line-jut"
                        style={{
                            width: `calc(100% * ${currentIndex + 1} / ${total})`
                        }}
                    />
                </div>
                <div className="pagination-indication-number || heading-5">
                    {totalAsString.length < 2 && '0'}{totalAsString}
                </div>
            </div>
        );
    }
}

PaginationIndication.propTypes = {
    total: PropTypes.number.isRequired,
    currentIndex: PropTypes.number.isRequired,
    fixedWidth: PropTypes.bool.isRequired
};

export default PaginationIndication;
