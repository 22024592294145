import formatContent from 'app/utilities/format-content';
import PropTypes from 'prop-types';
import React from 'react';

function CallToAction(props) { // eslint-disable-line require-jsdoc
    const { file, content } = props.data;

    return (
        <section className="call-to-action || content-section || constrain-width no-pad">
            <div className="call-to-action-inner">
                <div className="call-to-action-content || rte-content || rich-editor-content" dangerouslySetInnerHTML={{ __html: formatContent(content) }}></div>
                <div className="call-to-action-media || preserve-aspect-ratio" style={{ backgroundImage: `url(${file})` }}>
                    <div className="preserve-aspect-ratio-media">
                        <img className="fixed-image" src={file} alt={file} />
                    </div>
                </div>
            </div>
        </section>
    );
}

CallToAction.propTypes = {
    data: PropTypes.object.isRequired
};

export default CallToAction;
