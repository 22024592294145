import Icon from './icon';
import PropTypes from 'prop-types';
import React from 'react';

function SimpleDropdown({ options, setValue, value }) { // eslint-disable-line require-jsdoc
    const option = options.find(({ value: candidateValue }) => candidateValue === value);

    return (
        <div className="simple-dropdown">
            <div className="simple-dropdown-label">
                {option && option.label}
            </div>
            <Icon name="dropdown" />
            <select // eslint-disable-line jsx-a11y/no-onchange
                className="simple-dropdown-select"
                value={value}
                onChange={(event) => setValue(event.target.value)}
            >
                {options.map(({ value, label }) => (
                    <option key={value} value={value}>{label}</option>
                ))}
            </select>
        </div>
    );
}


SimpleDropdown.propTypes = {
    options: PropTypes.array.isRequired,
    setValue: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired
};

export default SimpleDropdown;
