/* eslint-disable require-jsdoc */
import { setMenuIsActiveAction } from 'app/ducks/menu';
import { setSearchDialogIsActiveAction } from 'app/ducks/search';
import { STOPPED_ROUTING } from 'app/ducks/status';

function createPostRouteMiddleware() {
    return ({ dispatch }) => (next) => (action) => {
        if (action && action.type === STOPPED_ROUTING) {
            dispatch([
                setSearchDialogIsActiveAction(false),
                setMenuIsActiveAction(false)
            ]);
        }

        next(action);
    };
}

const postRouteMiddleware = createPostRouteMiddleware();

export default postRouteMiddleware;
