import * as format from 'app/utilities/format';
import classnames from 'classnames';
import Helmet from 'react-helmet';
import Icon from './icon';
import PropTypes from 'prop-types';
import React from 'react';
import { EntityLink, getEntityLink } from 'rfa-react-core';
import { isAppLocal, isAppStaging, isAppUat } from 'config/env';

class SearchDialog extends React.Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
    }


    // Lifecycle
    componentDidUpdate(prevProps) {
        if (!prevProps.searchDialogIsActive && this.props.searchDialogIsActive && this.searchInput) {
            // Focus cursor on search input after user clicks on search icon
            this.searchInput.focus();
        }
    }

    generateSearchResultUrl(pathname) {
        let baseUrl = 'https://www.maritimemuseum.co.nz';

        if (isAppLocal) baseUrl = 'http://localhost:3000';
        if (isAppUat) baseUrl = 'https://maritime.uat.tauweb.co.nz';
        if (isAppStaging) baseUrl = 'https://maritime.staging.tauweb.co.nz';

        return `${baseUrl}${pathname}`;
    }

    // Handler for initial search
    handleSubmit(event) {
        event.preventDefault();
        this.props.initiateSearch(this.props.searchKeywords);
    }

    // Handler for load more
    handleLoadMore(event) {
        event.preventDefault();
        this.props.initiateSearch(this.props.searchKeywords, this.props.searchResults.nextPage);
    }

    // Render
    renderSearchResultCount(count) {
        if (!count) {
            return (
                <p className="search-results-not-found">
                    Sorry, your search query did not return any results.
                </p>
            );
        }

        return (
            <p className="search-results-found || heading-5">
                We found {count > 1 ? `${count} results` : '1 result'}
            </p>
        );
    }

    renderSearchResults(resultsPayload) {
        const { results: searchResults, hasMore } = resultsPayload;
        if (searchResults.length) {
            return (
                <section className="search-results-group">
                    <div className="search-results-group-inner" role="group">
                        {searchResults.map((searchResult, searchResultIndex) => {
                            const searchResultFormatted = format.searchResult(searchResult);

                            const pathname = getEntityLink({ item: searchResultFormatted, promoterBuName: 'Maritime Museum' }).to;

                            return (
                                <EntityLink
                                    key={searchResultIndex}
                                    item={searchResultFormatted}
                                    promoterBuName="Maritime Museum"
                                    className="search-results-group-item"
                                >
                                    <h3 className="search-results-group-item-heading">{searchResult.attributes.name}</h3>
                                    <span className="inline-link">{this.generateSearchResultUrl(pathname)}</span>
                                </EntityLink>
                            );
                        })}
                    </div>
                    {hasMore &&
                    <button
                        className="search-form-load-more || button alternative"
                        type="button"
                        title="Load More"
                        onClick={this.handleLoadMore}
                        disabled={this.props.isLoading}
                    >Load More</button>
                    }
                </section>
            );
        }

        return null;
    }

    renderLoaderOrSearchResults() {
        const { isBusy, searchResults, animateSearchResults } = this.props;

        if (isBusy) return null; // Not showing anything while the results are being loaded

        return (
            <div className={classnames({ 'animate-fade-left': animateSearchResults })}>
                {this.renderSearchResultCount(searchResults.count)}
                {this.renderSearchResults(searchResults)}
            </div>
        );
    }

    renderSearchForm() {
        const {
            searchKeywords,
            setSearchKeywords
        } = this.props;

        return (
            <form className="search-form" onSubmit={this.handleSubmit}>
                <input
                    className="search-form-input"
                    placeholder="Type here..."
                    type="search"
                    value={searchKeywords}
                    onChange={(event) => setSearchKeywords(event.target.value)}
                    name="q"
                    ref={(input) => { this.searchInput = input; }}
                    autoComplete="off"
                    autoCapitalize="off"
                    spellCheck={false}
                />
                <button className="search-form-submit || button alternative" type="submit" title="Search" disabled={this.props.isLoading}>
                    <Icon name="search" />
                    <span className="search-form-submit-label">Search</span>
                </button>
            </form>
        );
    }

    render() {
        const {
            searchDialogIsActive,
            searchActiveKeywords
        } = this.props;

        const searchClass = classnames('search-dialog', {
            'is-active': searchDialogIsActive
        });

        const bodyClass = classnames({ 'is-fixed': searchDialogIsActive });

        return (
            <aside
                role="dialog"
                className={searchClass}
                aria-hidden={searchDialogIsActive ? 'false' : 'true'}
            >
                <Helmet>
                    <body className={bodyClass} />
                </Helmet>
                <div className="constrain-width">
                    <div className="search-dialog-inner">
                        {this.renderSearchForm()}
                        {Boolean(searchActiveKeywords) && this.renderLoaderOrSearchResults()}
                    </div>
                </div>
            </aside>
        );
    }
}

SearchDialog.propTypes = {
    initiateSearch: PropTypes.func.isRequired,
    isBusy: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    searchDialogIsActive: PropTypes.bool.isRequired,
    searchKeywords: PropTypes.string.isRequired,
    searchActiveKeywords: PropTypes.string.isRequired,
    searchResults: PropTypes.shape({
        results: PropTypes.array,
        nextPage: PropTypes.number,
        count: PropTypes.number,
        hasMore: PropTypes.bool,
    }),
    animateSearchResults: PropTypes.bool.isRequired,
    setSearchKeywords: PropTypes.func.isRequired
};

export default SearchDialog;
