import PropTypes from 'prop-types';
import React from 'react';
import SimpleDropdown from 'app/components/partials/simple-dropdown';
import WhatsOn from './whats-on';
import { THIS_MONTH, THIS_WEEK } from 'config/whats-on';

const timeRangeOptions = [
    {
        value: THIS_WEEK,
        label: 'week'
    },
    {
        value: THIS_MONTH,
        label: 'month'
    }
];

class WhatsOnPageWhatsOnSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timeRange: timeRangeOptions[0].value
        };
    }

    // Life cycle
    componentDidMount() {
        this.props.initiateWhatsOnFetch(this.state.timeRange);
    }

    render() {
        const { timeRange } = this.state;
        const setTimeRange = (nextTimeRange) => {
            this.props.initiateWhatsOnFetch(nextTimeRange);
            this.setState({ timeRange: nextTimeRange });
        };

        return (
            <div>
                <section className="whats-on || content-section || constrain-width no-pad">
                    <header className="whats-on-header || constrain-width">
                        <h3>
                            What&apos;s happening this{' '}
                            <SimpleDropdown
                                options={timeRangeOptions}
                                value={timeRange}
                                setValue={setTimeRange}
                            />
                        </h3>
                    </header>
                    <WhatsOn items={this.props.whatsOnResults(timeRange)} />
                </section>
            </div>
        );
    }
}

WhatsOnPageWhatsOnSection.propTypes = {
    initiateWhatsOnFetch: PropTypes.func.isRequired,
    whatsOnResults: PropTypes.func.isRequired
};

export default WhatsOnPageWhatsOnSection;
