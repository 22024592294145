import classnames from 'classnames';
import Headroom from 'react-headroom';
import Icon from './icon';
import { Link } from 'react-router-dom';
import logo from 'static/images/logo.svg';
import logoPng from 'static/images/logo.png';
import Navigation from 'app/components/partials/navigation';
import NotificationBanner from 'app/components/partials/notification-banner.container';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const Header = (props) => {
    const {
        searchDialogIsActive,
        menuIsActive,
        navigationItemOpen,
        setSearchDialogIsActive,
        setMenuIsActive,
        setNavigationItemOpen,
        notifications,
        toggleNotifications,
        isNotificationsActive
    } = props;

    const searchButtonIsVisible = !menuIsActive && !searchDialogIsActive;
    const menuButtonIsVisible = !menuIsActive && !searchDialogIsActive;
    const bellButtonIsVisible = !menuIsActive && !searchDialogIsActive && notifications.length > 0;
    const closeButtonIsVisible = menuIsActive || searchDialogIsActive;
    const headroomIsDisabled = menuIsActive || searchDialogIsActive;
    const notificationsAreVisible = !menuIsActive && !searchDialogIsActive;

    useEffect(() => {
        props.fetchNotifications();
    }, []);

    return (
        <Headroom
            disable={headroomIsDisabled}
            disableInlineStyles={true}
        >
            {notificationsAreVisible && isNotificationsActive && (
                <NotificationBanner notifications={notifications} />
            )}
            <header className="header">
                <div className="header-inner || constrain-width">
                    <Link className="header-logo" to="/">
                        <img
                            width="190"
                            height="37"
                            className="logo"
                            src={logoPng}
                            srcSet={`${logoPng} 1x, ${logo} 1.5x`} // Display the detailed SVG on high-resolution screens only
                            alt="New Zealand Maritime Museum"
                        />
                    </Link>
                    <div className="header-strut" />
                    <Navigation
                        isActive={menuIsActive}
                        searchDialogIsActive={searchDialogIsActive}
                        itemOpen={navigationItemOpen}
                        updateOpenItem={(nextNavigationItemOpen) => {
                            setNavigationItemOpen(nextNavigationItemOpen);
                        }}
                    />
                    {searchButtonIsVisible && (
                        <button
                            type="button"
                            onClick={() => {
                                setMenuIsActive(false);
                                setSearchDialogIsActive(true);
                            }}
                            className="icon-button"
                        >
                            <Icon name="search" />
                        </button>
                    )}
                    {bellButtonIsVisible && (
                        <button
                            type="button"
                            aria-label="Toggle Notifications"
                            className={classnames('icon-button || header-notification-toggle', {
                                'is-active': isNotificationsActive
                            })}
                            onClick={() => toggleNotifications()}>
                            <Icon name="bell" title="Notifications" />
                        </button>
                    )}
                    {menuButtonIsVisible && (
                        <button
                            type="button"
                            onClick={() => {
                                setMenuIsActive(true);
                                setSearchDialogIsActive(false);
                            }}
                            className="header-menu-button || icon-button"
                        >
                            <Icon name="menu" />
                        </button>
                    )}
                    {closeButtonIsVisible && (
                        <button
                            type="button"
                            onClick={() => {
                                setMenuIsActive(false);
                                setSearchDialogIsActive(false);
                            }}
                            className="header-close-button || button icon-button"
                        >
                            <span className="header-close-button-label">Close</span>
                            <Icon name="close" />
                        </button>
                    )}
                </div>
            </header>
        </Headroom>
    );
};

Header.propTypes = {
    searchDialogIsActive: PropTypes.bool.isRequired,
    menuIsActive: PropTypes.bool.isRequired,
    navigationItemOpen: PropTypes.number, // Not required so we can pass null
    setSearchDialogIsActive: PropTypes.func.isRequired,
    setMenuIsActive: PropTypes.func.isRequired,
    setNavigationItemOpen: PropTypes.func.isRequired,
    status: PropTypes.shape({
        isRouting: PropTypes.bool.isRequired
    }).isRequired,
    fetchNotifications: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
    isNotificationsActive: PropTypes.bool.isRequired,
    toggleNotifications: PropTypes.func.isRequired
};

export default Header;
