import { convertUrlToIframeSource } from 'app/utilities/youtube';
import PropTypes from 'prop-types';
import React from 'react';

function Video(props) { // eslint-disable-line require-jsdoc
    const { title, credit, video_url: videoUrl } = props.data;
    const videoSource = convertUrlToIframeSource(videoUrl);

    return (
        <section className="content-section">
            <div className="video-content-block || constrain-width">
                <div className="video-content-block-content">
                    <h2 className="primary">{title}</h2>
                    {credit && <p>{credit}</p>}
                </div>
                <div className="video-content-block-inner || preserve-aspect-ratio video">
                    <div className="preserve-aspect-ratio-media">
                        <iframe
                            title={title}
                            className="video"
                            src={videoSource}
                            allowFullScreen={true}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

Video.propTypes = {
    data: PropTypes.object.isRequired
};

export default Video;
