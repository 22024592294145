import classnames from 'classnames';
import Icon from './icon';
import PropTypes from 'prop-types';
import React from 'react';

function Dropdown({ options, value, placeholder, isValid, required, setValue, setTouched }) { // eslint-disable-line require-jsdoc
    const option = options.find(({ value: candidateValue }) => candidateValue === value);

    return (
        <div className={classnames('dropdown || form-control', { 'is-invalid': !isValid })}>
            <div className={classnames('dropdown-label', { 'dropdown-label-placeholder': !option })}>
                {option ? option.label : placeholder}
            </div>
            <Icon name="dropdown" />
            <select
                className="dropdown-select"
                required={required}
                value={value}
                onChange={(event) => setValue(event.target.value)}
                onBlur={() => setTouched(true)}
            >
                <option value="" disabled>{placeholder}</option>
                {options.map(({ value, label }) => (
                    <option key={value} value={value}>{label}</option>
                ))}
            </select>
        </div>
    );
}


Dropdown.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.any.isRequired,
    placeholder: PropTypes.string.isRequired,
    isValid: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
    setValue: PropTypes.func.isRequired,
    setTouched: PropTypes.func.isRequired
};

export default Dropdown;
