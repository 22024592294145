import Error from './error/error-page';
import Helmet from 'react-helmet';
import { isAppProd } from 'config/env';
import NotFound from './error/not-found-page';
import PropTypes from 'prop-types';
import React from 'react';
import { RESPONSE } from 'config/api';
import Template from 'app/components/templates/index';
import templateType from 'app/utilities/template-type';

const ContentPage = ({ page }) => {
    // Render not error/not found page
    if (!page || page.error && page.error.status === RESPONSE.INTERNAL_SERVER_ERROR) return <Error />;
    if (page.error && page.error.status === RESPONSE.NOT_FOUND || !page.attributes) return <NotFound />;

    const { type, attributes } = page;
    const {
        is_published: isPublished,
        name: title,
        seo_description: seoDescription,
        seo_keywords: seoKeywords,
        seo_title: seoTitle,
        slug,
        main_images: mainImages,
        thumbnail
    } = attributes;

    const socialCardTitle = seoTitle || title;
    const image = mainImages && mainImages[0] || thumbnail;
    const isNoIndex = !isAppProd || !isPublished;

    return (
        <main className="page" role="main">
            <Helmet>
                {isNoIndex && <meta name="robots" content="noindex, nofollow" />}

                <title>{title}</title>
                <meta name="description" content={seoDescription} />
                <meta name="keywords" content={seoKeywords} />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={socialCardTitle} />
                <meta name="twitter:description" content={seoDescription} />
                {image && <meta name="twitter:image" content={image} />}

                {/* Open Graph */}
                <meta property="og:locale" content="en_NZ" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={socialCardTitle} />
                <meta property="og:description" content={seoDescription} />
                {image && <meta property="og:image" content={image} />}
            </Helmet>
            <Template
                type={templateType(slug, type)}
                data={page.attributes}
            />
        </main>
    );
};

ContentPage.propTypes = {
    page: PropTypes.object.isRequired
};

export default ContentPage;
