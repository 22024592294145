import CallToAction from 'app/components/content-blocks/call-to-action';
import ContentSet from 'app/components/content-blocks/content-set';
import Faq from 'app/components/content-blocks/faq';
import FileDownload from 'app/components/content-blocks/file-download';
import Helmet from 'react-helmet';
import HomepageHeroCarousel from 'app/components/partials/homepage-hero-carousel';
import Icon from 'app/components/partials/icon';
import ImageCarousel from 'app/components/content-blocks/image-carousel';
import ImageGalleryDouble from '../partials/image-gallery-double';
import ImageGallerySingle from 'app/components/partials/image-gallery-single';
import Jotform from 'app/components/content-blocks/jotform';
import { Link } from 'react-router-dom';
import Loader from 'app/components/partials/loader';
import MOCK_DATA from 'config/mock-data';
import Newsletter from 'app/components/content-blocks/newsletter';
import PaginationIndication from 'app/components/partials/pagination-indication';
import Review from 'app/components/content-blocks/review';
import Section from 'app/components/partials/style-guide-section';
import StacklaWidget from 'app/components/content-blocks/stackla-widget';
import Swatch from 'app/components/partials/swatch';
import TextContent from 'app/components/content-blocks/text-content';
import Video from '../content-blocks/video';
import WhatsOn from 'app/components/partials/whats-on';
import React, { Component } from 'react';

const pageMeta = {
    title: 'Style Guide',
    introduction: 'A working site style guide built to manage design and code quality control.'
};

class StyleGuidePage extends Component {
    render() {
        return (
            <main className="page" role="main">
                <Helmet>
                    <title>{pageMeta.title}</title>
                    <meta name="description" content={pageMeta.introduction} />
                    <meta name="keywords" content="" />
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>

                <header className="style-guide-header || constrain-width">
                    <h1 className="heading-2">Style Guide</h1>
                </header>

                <Section title="Colours" type="constrain">
                    <div className="style-guide-swatches">
                        {MOCK_DATA.SWATCH.items.map((swatch, index) => <Swatch key={index} {...swatch} />)}
                    </div>
                </Section>

                <Section title="Typography" type="constrain">
                    <h1>Heading 1</h1>
                    <h2 className="primary">Heading 2</h2>
                    <h3>Heading 3</h3>
                    <h4>Heading 4</h4>
                    <h5>Heading 5</h5>

                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                    <p>Ut wisi enim ad minim veniam, quis nostrud <strong>exerci tation ullamcorper suscipit</strong> lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit <em>in vulputate velit esse molestie</em> consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Nam liber tempor cum soluta nobis eleifend option congue.</p>
                    <p>Nihil imperdiet doming id <a href="#" title="Link">quod mazim placerat</a> facer possim assum. Typi non habent claritatem insitam; est usus legentis in iis qui facit eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum formas humanitatis per seacula quarta decima et quinta decima. Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes in futurum.</p>
                </Section>

                <Section title="Icons" type="constrain">
                    <Icon name="right-arrow" />
                    <Icon name="plus" />
                    <Icon name="minus" />
                    <Icon name="expand" />
                    <Icon name="alert" />
                    <Icon name="clock" />
                    <Icon name="star" />
                    <Icon name="close" />
                    <Icon name="dates" />
                    <Icon name="dropdown" />
                    <Icon name="duration" />
                    <Icon name="location" />
                    <Icon name="menu" />
                    <Icon name="price" />
                    <Icon name="right" />
                    <Icon name="search" />
                </Section>

                <Section title="Loader" type="constrain">
                    <Loader />
                </Section>

                <Section title="Lists" type="constrain">
                    <h3>Unordered</h3>
                    <ul>
                        <li>There are many variations of passages of Lorem Ipsum available, but the majority have.</li>
                        <li>Making this the first true generator on the Internet. It uses a dictionary of over.</li>
                        <li>The generated Lorem Ipsum is therefore always free from repetition, injected humour.</li>
                    </ul>

                    <h3>Ordered</h3>
                    <ol>
                        <li>There are many variations of passages of Lorem Ipsum available, but the majority have.</li>
                        <li>Making this the first true generator on the Internet. It uses a dictionary of over.</li>
                        <li>The generated Lorem Ipsum is therefore always free from repetition, injected humour.</li>
                    </ol>

                    <h3>Icons</h3>
                    <ul>
                        <li className="icon">
                            <Icon name="clock" />
                            Time (eg. 18:00 - 20:00)
                        </li>
                        <li className="icon">
                            <Icon name="duration" />
                            Duration (eg. 2 hours)
                        </li>
                        <li className="icon">
                            <Icon name="location" />
                            Address (eg. Viaduct Harbour)
                        </li>
                        <li className="icon">
                            <Icon name="price" />
                            Price (eg. $14 child, $22 adult)
                        </li>
                        <li className="icon">
                            <Icon name="dates" />
                            Dates (eg. 23.1 - 19.2)
                        </li>
                    </ul>
                </Section>

                <Section title="Buttons" type="constrain">
                    <h4 className="style-guide-section-button-title">Default</h4>
                    <div role="group" className="style-guide-button-group">
                        <Link className="button" to="/">Default</Link>
                        <Link className="button is-active" to="/">Active</Link>
                        <Link className="button is-disabled" to="/">Disabled</Link>
                    </div>

                    <h4 className="style-guide-section-button-title">Alternative</h4>
                    <div role="group" className="style-guide-button-group">
                        <Link className="button alternative" to="/">Default</Link>
                        <Link className="button alternative is-active" to="/">Active</Link>
                        <Link className="button alternative is-disabled" to="/">Disabled</Link>
                    </div>

                    <h4 className="style-guide-section-button-title">Text link</h4>
                    <div role="group" className="style-guide-button-group">
                        <Link className="text-link" to="/">
                            Link to a page
                            <Icon name="right-arrow" />
                        </Link>

                        <div className="button-group">
                            <button className="previous || button alternative">
                                <Icon name="right" title="Previous" />
                            </button>
                            <button className="button alternative">
                                <Icon name="right" title="Next" />
                            </button>
                        </div>
                    </div>
                </Section>

                <Section title="Pagination" type="constrain">
                    <h4 className="style-guide-section-button-title">Indication</h4>
                    <PaginationIndication total={4} currentIndex={0} fixedWidth />
                </Section>

                <Section title="Homepage Hero Carousel" type="constrain">
                    <p className="constrain-width">
                        This content block requires <b>large landscape images</b>. Recommended dimensions are <b>1920px × 1080px</b>.
                    </p>
                    <HomepageHeroCarousel data={MOCK_DATA.HOMEPAGE_HERO_CAROUSEL_DATA} globalFields={MOCK_DATA.GLOBAL_FIELDS}/>
                </Section>

                <Section title="Text Content" type="constrain">
                    <TextContent {...MOCK_DATA.TEXT_PROPS} />

                    <TextContent {...MOCK_DATA.TEXT_ASIDE_PROPS} />
                </Section>

                <Section title="Call to action">
                    <p className="constrain-width">
                        This content block requires <b>large-to-medium-sized square images</b>. Recommended dimensions are <b>1080px × 1080px</b>. Max size is <b>500kb</b>.
                    </p>
                    <CallToAction {...MOCK_DATA.CALL_TO_ACTION_PROPS} />
                </Section>

                <Section title="Image Gallery: Single">
                    <p className="constrain-width">
                        This content block requires <b>large-to-medium-sized square images</b>. Recommended dimensions are <b>1080px × 1080px</b>. Max size is <b>500kb</b>.
                    </p>
                    <ImageGallerySingle {...MOCK_DATA.IMAGE_GALLERY_SINGLE_PROPS} />
                </Section>

                <Section title="Image Gallery: Double">
                    <p className="constrain-width">
                        This content block requires <b>large-to-medium-sized square images</b>. Recommended dimensions are <b>1080px × 1080px</b>. Max size is <b>500kb</b>.
                    </p>
                    <ImageGalleryDouble {...MOCK_DATA.IMAGE_GALLERY_DOUBLE_PROPS} />
                </Section>

                <Section title="Content set">
                    <p className="constrain-width">
                        Use <b>small square thumbnail images</b>. Recommended dimensions are <b>540px × 540px</b>.
                    </p>
                    <ContentSet {...MOCK_DATA.CONTENT_SET_PROPS} />
                </Section>

                <Section title="Review">
                    <Review {...MOCK_DATA.REVIEW_PROPS} />
                </Section>

                <Section title="Image Carousel">
                    <p className="constrain-width">
                        This content block requires <b>large landscape images</b>. Recommended dimensions are <b>1920px × 1080px</b>. Max size is <b>500kb</b>.
                    </p>
                    <ImageCarousel {...MOCK_DATA.IMAGE_CAROUSEL_PROPS} />
                </Section>

                <Section title="FAQ">
                    <Faq {...MOCK_DATA.FAQ_PROPS} />
                </Section>

                <Section title="File download">
                    <FileDownload {...MOCK_DATA.FILE_DOWNLOAD_PROPS} />
                </Section>

                <Section title="What's on section">
                    <p className="constrain-width">
                        Use <b>small square thumbnail images</b>. Recommended dimensions are <b>540px × 540px</b>.
                    </p>
                    <WhatsOn {...MOCK_DATA.WHATS_ON_PROPS} />
                </Section>

                <Section title="Video" type="constrain">
                    <Video {...MOCK_DATA.VIDEO_PROPS} />
                </Section>

                <Section title="Newsletter" type="constrain">
                    <Newsletter />
                </Section>

                <Section title="JotForm" type="constrain">
                    <Jotform form_id="91960595325869" />
                </Section>

                <Section title="Stackla" type="constrain">
                    <StacklaWidget {...MOCK_DATA.STACKLA_PROPS} />
                </Section>
            </main>
        );
    }
}

export default StyleGuidePage;
