import React from 'react';

function createEventJsonLd(data) { // eslint-disable-line require-jsdoc
    const {
        main_images: mainImages,
        name,
        seo_description: seoDescription,
        start_date: startDate,
        end_date: endDate,
        location,
        location_physical_address: locationPhysicalAddress
    } = data;

    return {
        '@context': 'https://schema.org',
        '@type': 'Event',
        name,
        startDate,
        endDate,
        location: location && {
            '@type': 'Place',
            name: location,
            address: locationPhysicalAddress && {
                '@type': 'PostalAddress',
                name: locationPhysicalAddress
            } || []
        } || [],
        image: mainImages && mainImages.length && mainImages[0] || [],
        description: seoDescription
    };
}

export default (data) => ( // eslint-disable-line react/display-name
    <div>
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(createEventJsonLd(data)) }}
        />
    </div>
);
