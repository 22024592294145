import NewsletterSignup from './newsletter-signup';
import React from 'react';
import SocialProfileLinks from './social-profile-links';

function NewsletterSignupAndSocialProfileLinks() { // eslint-disable-line require-jsdoc
    return (
        <section className="newsletter-signup-and-social-profile-links">
            <div className="newsletter-signup-and-social-profile-links-inner || constrain-width">
                <div className="newsletter-signup-wrapper">
                    <NewsletterSignup />
                </div>
                <div className="social-profile-links-wrapper">
                    <SocialProfileLinks />
                </div>
            </div>
        </section>
    );
}

export default NewsletterSignupAndSocialProfileLinks;
