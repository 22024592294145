/* eslint-disable require-jsdoc */

import { connect } from 'react-redux';
import { initiateWhatsOnFetchAction } from 'app/ducks/whats-on';
import { selectWhatsOnResultsFromCache } from 'app/selectors/whats-on';
import WhatsOnPageComingUpSection from './whats-on-page-coming-up-section';
import { ALL_ENTITIES_BUT_SAILINGS, COMING_UP } from 'config/whats-on';

function mapStateToProps(state) {
    return {
        whatsOnResults: selectWhatsOnResultsFromCache(state, COMING_UP, ALL_ENTITIES_BUT_SAILINGS, true)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        initiateWhatsOnFetch: () => dispatch(initiateWhatsOnFetchAction(COMING_UP, ALL_ENTITIES_BUT_SAILINGS, true))
    };
}

const VisibleWhatsOnPageComingUpSection = connect(
    mapStateToProps,
    mapDispatchToProps
)(WhatsOnPageComingUpSection);

export default VisibleWhatsOnPageComingUpSection;
