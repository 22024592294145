export const ROUTABLE_ENTITIES = {
    educations: {
        default: 'education',
        custom: null
    },
    events: {
        default: 'event',
        custom: null
    },
    exhibitions: {
        default: 'exhibition',
        custom: null
    },
    pages: {
        default: 'page',
        custom: [
            'home',
            'contact-us',
            'whats-on',
            'learn',
            'book-now'
        ]
    },
    sailings: {
        default: 'sailing',
        custom: null
    },
    stories: {
        default: 'story',
        custom: null
    }
};

export const DEFAULT_TEMPLATE = ROUTABLE_ENTITIES.pages.default;


/**
 * Returns the template type using the entity slug and type. A template type is
 * is used to define which React template to render – see
 * app/components/templates/index.jsx
 *
 * @param  {string} slug entity slug
 * @param  {string} type entity type
 *
 * @return {string} templateType
 */
export function templateType(slug, type) {
    if (type in ROUTABLE_ENTITIES) {
        if (ROUTABLE_ENTITIES[type].custom && ROUTABLE_ENTITIES[type].custom.includes(slug)) {
            return slug;
        }

        return ROUTABLE_ENTITIES[type].default;
    }

    return DEFAULT_TEMPLATE;
}

export default templateType;
