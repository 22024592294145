import canUseDom from './dom';
import { ENDPOINTS } from 'config/api';
import { getJsonStr } from 'app/utilities/format';
import { url } from 'rfa-react-core';

const CMS_PAGE_DEPTH = 2;
export const ENTITY_TYPE = {
    PAGE: 'page',
    EVENTS: 'events',
    EXHIBITIONS: 'exhibitions',
    SAILINGS: 'sailings',
    EDUCATIONS: 'educations',
    STORIES: 'stories'
};

export const getSlug = (reqUrl) => {
    return url.getSlug(reqUrl);
};

export const getParent = (reqUrl) => {
    return url.getParent(reqUrl);
};

export const getEntityType = (reqUrl) => {
    const arr = reqUrl.split('/');
    const base = arr.length > CMS_PAGE_DEPTH ? arr[1] : '';
    switch (base) {
        case ENTITY_TYPE.EVENTS:
            return ENTITY_TYPE.EVENTS;
        case ENTITY_TYPE.EXHIBITIONS:
            return ENTITY_TYPE.EXHIBITIONS;
        case ENTITY_TYPE.SAILINGS:
            return ENTITY_TYPE.SAILINGS;
        case ENTITY_TYPE.EDUCATIONS:
            return ENTITY_TYPE.EDUCATIONS;
        case ENTITY_TYPE.STORIES:
            return ENTITY_TYPE.STORIES;
        default:
            return ENTITY_TYPE.PAGE;
    }
};

export const getEndpoint = (entityType) => {
    switch (entityType) {
        case ENTITY_TYPE.EVENTS:
            return ENDPOINTS.EVENTS;
        case ENTITY_TYPE.EXHIBITIONS:
            return ENDPOINTS.EXHIBITIONS;
        case ENTITY_TYPE.SAILINGS:
            return ENDPOINTS.SAILINGS;
        case ENTITY_TYPE.EDUCATIONS:
            return ENDPOINTS.EDUCATIONS;
        case ENTITY_TYPE.STORIES:
            return ENDPOINTS.STORIES;
        default:
            return ENDPOINTS.PAGES;
    }
};

export const getApiUrl = (reqUrl) => {
    return url.getApiUrl(reqUrl, getEntityType, getEndpoint);
};

export const getRouteArray = (reqUrl) => {
    return url.getRouteArray(reqUrl);
};

export const buildUrl = (path, params) => {
    const paramsString = Object.keys(params)
        .filter((param) => Boolean(params[param]))
        .map((param) => {
            return `${encodeURIComponent(param)}=${encodeURIComponent(params[param])}`;
        })
        .join('&');

    return `${path}${paramsString ? '?' : ''}${paramsString}`;
};

/**
 * `Entity` is one of the concepts from `CMS`, it looks like a `category` under every `project` inside `CMS`.
 *
 * @param {any} entityType
 */
const getEndpointArrByEntity = (entityType) => {
    const pageEnpoints = {
        dataKey: 'page',
        endPoint: ENDPOINTS.PAGES,
        apiUrl: ''
    };

    const eventEndpoints = {
        dataKey: 'page',
        endPoint: ENDPOINTS.EVENTS,
        apiUrl: ''
    };

    const exhibitionEndpoints = {
        dataKey: 'page',
        endPoint: ENDPOINTS.EXHIBITIONS,
        apiUrl: ''
    };

    const sailingEndpoints = {
        dataKey: 'page',
        endPoint: ENDPOINTS.SAILINGS,
        apiUrl: ''
    };

    const educationEndpoints = {
        dataKey: 'page',
        endPoint: ENDPOINTS.EDUCATIONS,
        apiUrl: ''
    };

    const storyEndpoints = {
        dataKey: 'page',
        endPoint: ENDPOINTS.STORIES,
        apiUrl: ''
    };

    const endpointsArr = [
        {
            dataKey: 'globalFields',
            endPoint: ENDPOINTS.GLOBAL_FIELDS,
            apiUrl: ''
        },
    ];

    switch (entityType) {
        case ENTITY_TYPE.EVENTS:
            endpointsArr.unshift(eventEndpoints);
            break;
        case ENTITY_TYPE.EXHIBITIONS:
            endpointsArr.unshift(exhibitionEndpoints);
            break;
        case ENTITY_TYPE.SAILINGS:
            endpointsArr.unshift(sailingEndpoints);
            break;
        case ENTITY_TYPE.EDUCATIONS:
            endpointsArr.unshift(educationEndpoints);
            break;
        case ENTITY_TYPE.STORIES:
            endpointsArr.unshift(storyEndpoints);
            break;
        default:
            endpointsArr.unshift(pageEnpoints);
            break;
    }

    return endpointsArr;
};

const getApiRequest = (reqUrl, entityTypeHandler, endpointHandler) => {
    const entityType = entityTypeHandler(reqUrl);
    const endpointArr = endpointHandler(entityType);
    const slug = getSlug(reqUrl);

    if (!canUseDom()) {
        console.log('getApiRequest', 'entityType', entityType);
        console.log('getApiRequest', 'endpointArr', getJsonStr(endpointArr));
    }

    // Calulate the `apiUrl` for each endpoit
    const requestEndpoints = endpointArr.map((tempEndpoint) => {
        // `enpointArr[?].enpoint` can be `string | function`
        if (typeof tempEndpoint.endPoint === 'function') {
            let apiUrl = '';

            if (tempEndpoint.endPoint === ENDPOINTS.PAGES) {
                apiUrl = tempEndpoint.endPoint(slug, getParent(reqUrl));
            } else {
                apiUrl = tempEndpoint.endPoint(slug);
            }

            return Object.assign({}, tempEndpoint, { apiUrl: apiUrl });
        }

        return Object.assign({}, tempEndpoint, { apiUrl: tempEndpoint.endPoint });
    });

    return requestEndpoints;
};

export const getApiRequestArr = (reqUrl) => {
    return getApiRequest(reqUrl, getEntityType, getEndpointArrByEntity);
};

// trim forward slashes and cast to lower case
export const normalizePath = (path) => {
    return path.replace(/^\/+/, '').toLowerCase();
};

export const isAbsoluteUrl = (url) => {
    return url.startsWith('http://') || url.startsWith('https://');
};
