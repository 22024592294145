/* eslint-disable require-jsdoc */
import { connect } from 'react-redux';
import SearchDialog from './search-dialog';
import {
    initiateSearchResultFetchAction,
    setSearchKeywordsAction
} from 'app/ducks/search';
import {
    selectAnimateSearchResults,
    selectIsBusy,
    selectIsLoading,
    selectSearchActiveKeywords,
    selectSearchDialogIsActive,
    selectSearchKeywords,
    selectSearchResults
} from 'app/selectors/search';

function mapStateToProps(state) {
    return {
        isBusy: selectIsBusy(state),
        isLoading: selectIsLoading(state),
        searchDialogIsActive: selectSearchDialogIsActive(state),
        searchKeywords: selectSearchKeywords(state),
        searchActiveKeywords: selectSearchActiveKeywords(state),
        searchResults: selectSearchResults(state),
        animateSearchResults: selectAnimateSearchResults(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        initiateSearch: (keywords, page) => dispatch(initiateSearchResultFetchAction(keywords, page)),
        setSearchKeywords: (keywords) => dispatch(setSearchKeywordsAction(keywords))
    };
}

const VisibleSearchDialog = connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchDialog);

export default VisibleSearchDialog;
