import ContentBlocks from 'app/components/content-blocks/index';
import ErrorPageImage from 'static/images/error-page.png';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

function Story(props) {
    const {
        author,
        blocks,
        main_images: mainImages,
        name,
        publish_at: publishAt,
        short_description: shortDescription
    } = props.data;

    const imageSrc = mainImages && mainImages[0] || ErrorPageImage;

    // Inject an image gallery content block
    const item = {
        creditline: null,
        creditlineJsx: (
            <div>
                <p>
                    <b>{moment(publishAt).format('DD.MM.YY')}</b>
                </p>
                <h2>{name}</h2>
                {shortDescription && <p>{shortDescription}</p>}
                {author && <p>By <i>{author}</i></p>}
            </div>
        ),
        file: imageSrc,
        url: null,
        buttonText: null
    };

    const blocksExtended = [
        {
            type: 'gallery',
            data: [item]
        },
        ...blocks
    ];

    return (
        <div className="content-page">
            <ContentBlocks data={blocksExtended} />
        </div>
    );
}

Story.propTypes = {
    data: PropTypes.object.isRequired
};

export default Story;
