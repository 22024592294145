import Notification from './notification';
import PropTypes from 'prop-types';
import React from 'react';

const NotificationBanner = ({ notifications, updateNotification }) => {
    // Handler
    const handleToggle = (id) => {
        updateNotification(id);
    };

    return (
        <div className="notification-banner">
            {notifications && notifications.map((item, index) => {
                const {
                    description,
                    isPublished,
                    id,
                    title,
                    type,
                    url
                } = item;

                return (
                    <Notification
                        description={description}
                        id={id}
                        title={title}
                        type={type}
                        url={url}
                        handleToggle={handleToggle}
                        isVisible={isPublished}
                        key={index}
                    />
                );
            })}
        </div>
    );
};

NotificationBanner.propTypes = {
    notifications: PropTypes.array.isRequired,
    updateNotification: PropTypes.func.isRequired
};

export default NotificationBanner;
