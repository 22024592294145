import { dataLayerPush } from 'app/utilities/gtm';
import Icon from './icon';
import React from 'react';

/**
 * Push records to the data layer when the user clicks social share icons.
 * @param {string} text - text property of the data layer record (social network name).
 * @return {function}
 */
function trackSocialClick(text) {
    dataLayerPush({
        id: 'micro_conversion',
        type: 'engaging_content',
        action: 'Social Click',
        text
    });
}

function SocialProfileLinks() { // eslint-disable-line require-jsdoc
    return (
        <div className="social-profile-links">
            <a
                className="social-profile-link"
                href="https://www.facebook.com/nzmaritimemuseum"
                target="_blank"
                rel="noopener noreferrer"
            >
                <Icon
                    name="facebook"
                    onClick={() => {
                        trackSocialClick('facebook');
                    }}
                />
            </a>
            <a
                className="social-profile-link"
                href="https://www.youtube.com/channel/UCGvYjwhqU86GoB8V2Unc02A"
                target="_blank"
                rel="noopener noreferrer"
            >
                <Icon
                    name="youtube"
                    onClick={() => {
                        trackSocialClick('youtube');
                    }}
                />
            </a>
            <a
                className="social-profile-link"
                href="https://www.instagram.com/nzmaritimemuseum/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <Icon
                    name="instagram"
                    onClick={() => {
                        trackSocialClick('instagram');
                    }}
                />
            </a>
            <a
                className="social-profile-link"
                href="https://www.tripadvisor.co.nz/Attraction_Review-g255106-d256869-Reviews-New_Zealand_Maritime_Museum-Auckland_North_Island.html"
                target="_blank"
                rel="noopener noreferrer"
            >
                <Icon
                    name="tripadvisor"
                    onClick={() => {
                        trackSocialClick('tripadvisor');
                    }}
                />
            </a>
        </div>
    );
}

export default SocialProfileLinks;
