/* eslint-disable require-jsdoc */

import { connect } from 'react-redux';
import { initiateWhatsOnFetchAction } from 'app/ducks/whats-on';
import { selectWhatsOnResultsFromCache } from 'app/selectors/whats-on';
import WhatsOnPageFeaturedSection from './whats-on-page-featured-section';
import { ALL_ENTITIES, FUTURE } from 'config/whats-on';

function mapStateToProps(state) {
    return {
        whatsOnResults: selectWhatsOnResultsFromCache(state, FUTURE, ALL_ENTITIES, false, true)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        initiateWhatsOnFetch: () => dispatch(initiateWhatsOnFetchAction(FUTURE, ALL_ENTITIES, false, true))
    };
}

const VisibleWhatsOnPageFeaturedSection = connect(
    mapStateToProps,
    mapDispatchToProps
)(WhatsOnPageFeaturedSection);

export default VisibleWhatsOnPageFeaturedSection;
