import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { getLocal } from 'app/utilities/local-storage';
import { mergeMap  } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import {
    filterNotifications,
    hideNotification,
    NOTIFICATIONS_KEY,
    updateNotifications
} from 'app/utilities/notifications';


export const INITIAL_STATE = {
    notifications: [],
    isActive: false
};


// Actions
export const FETCH_NOTIFICATIONS = 'rfa-maritime-website/notifications/FETCH_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_SUCCESS = 'rfa-maritime-website/notifications/FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAIL = 'rfa-maritime-website/notifications/FETCH_NOTIFICATIONS_FAIL';
export const TOGGLE_NOTIFICATIONS = 'rfa-maritime-website/notifications/TOGGLE_NOTIFICATIONS';
export const UPDATE_NOTIFICATION = 'rfa-maritime-website/notifications/UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_SUCCESS = 'rfa-maritime-website/notifications/UPDATE_NOTIFICATION_SUCCESS';
export const SHOW_NOTIFICATION = 'rfa-maritime-website/notifications/SHOW_NOTIFICATION';


// Actions Creator
export const fetchNotificationsAction = () => ({
    type: FETCH_NOTIFICATIONS
});


export const fetchNotificationsFailAction = (notifications) => ({
    type: FETCH_NOTIFICATIONS_FAIL,
    payload: {
        notifications
    }
});

export const fetchNotificationsSuccessAction = (notifications) => ({
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: {
        notifications
    }
});

export const toggleNotificationsAction = () => ({
    type: TOGGLE_NOTIFICATIONS
});

export const updateNotificationAction = (notificationId) => ({
    type: UPDATE_NOTIFICATION,
    payload: {
        notificationId
    }
});

export const updateNotificationSuccessAction = (notifications) => ({
    type: UPDATE_NOTIFICATION_SUCCESS,
    payload: {
        notifications
    }
});

export const showNotificationAction = () => ({
    type: SHOW_NOTIFICATION
});


// Reducers
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_NOTIFICATIONS:
        case UPDATE_NOTIFICATION:
            return state;
        case FETCH_NOTIFICATIONS_FAIL:
        case FETCH_NOTIFICATIONS_SUCCESS:
        case UPDATE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notifications: payload.notifications
            };
        case TOGGLE_NOTIFICATIONS:
            return { ...state, isActive: !state.isActive };
        case SHOW_NOTIFICATION:
            return { ...state, isActive: true };
        default:
            return state;
    }
};

// Epic creator
/**
 * Fetch Notifications Epic
 * @param  {func} endpoint                        - Notifications endpoint
 * @param  {func} fetchNotificationsSuccessAction - Success action creator
 * @param  {func} fetchNotificationsFailAction    - Fail action creator
 * @param  {func} showNotificationAction          - Show notification action creator
 * @return {func}
 */
export const createNotificationsEpic = (
    endpoint,
    fetchNotificationsSuccessAction,
    fetchNotificationsFailAction,
    showNotificationAction,
) => {
    return (action$) => action$.pipe(
        ofType(FETCH_NOTIFICATIONS),
        mergeMap(() => {
            return (
                fetch(endpoint)
                    .then((response) => {
                        const filteredNotifications = filterNotifications(response.data);
                        const hasUnseenNotifications = updateNotifications(filteredNotifications);
                        const updatedNotifications = getLocal(NOTIFICATIONS_KEY);

                        if (hasUnseenNotifications) {
                            return [fetchNotificationsSuccessAction(updatedNotifications), showNotificationAction()];
                        }

                        return fetchNotificationsSuccessAction(updatedNotifications);
                    })
                    .catch(() => fetchNotificationsFailAction([]))
            );
        })
    );
};

/**
 * Update Notifications Epic
 * @param  {func} updateNotificationSuccessAction  - Update Notifications Success Action
 * @param  {func} toggleNotificationsAction        - Toggle Notifications Action
 * @return {func}
 */
export const createUpdateNotificationsEpic = (
    updateNotificationSuccessAction,
    toggleNotificationsAction
) => {
    return (action$) => action$.pipe(
        ofType(UPDATE_NOTIFICATION),
        mergeMap(({ payload }) => {
            let notifications = [];
            notifications = hideNotification(payload.notificationId);

            return [updateNotificationSuccessAction(notifications), toggleNotificationsAction()];
        })
    );
};

// Epic
const notificationsEpic = createNotificationsEpic(
    ENDPOINTS.NOTIFICATIONS,
    fetchNotificationsSuccessAction,
    fetchNotificationsFailAction,
    showNotificationAction
);

const updateNotificationsEpic = createUpdateNotificationsEpic(
    updateNotificationSuccessAction,
    toggleNotificationsAction
);

export const epics = combineEpics(
    notificationsEpic,
    updateNotificationsEpic
);

