import BookNow from './book-now';
import ContactUs from './contact-us';
import Event from './event';
import Home from './home';
import Learn from './learn';
import Page from './page';
import PropTypes from 'prop-types';
import React from 'react';
import Story from './story';
import WhatsOn from './whats-on';

// eslint-disable-next-line require-jsdoc
function Template({ data, type }) {
    // TODO: This relies on slug determining the page type
    switch (type) {
        case 'home':
            return <Home data={data} />;
        case 'contact-us':
            return <ContactUs data={data} />;
        case 'whats-on':
            return <WhatsOn data={data} />;
        case 'learn':
            return <Learn data={data} />;
        case 'book-now':
            return <BookNow data={data} />;
        case 'event':
        case 'exhibition':
        case 'sailing':
            return <Event data={data} />;
        case 'story':
            return <Story data={data} />;
        case 'education':
        default:
            return <Page data={data} />;
    }
}

Template.propTypes = {
    data: PropTypes.object,
    type: PropTypes.string.isRequired
};

export default Template;
