/* eslint-disable require-jsdoc */

import { ALL_ENTITIES } from 'config/whats-on';
import { connect } from 'react-redux';
import { initiateWhatsOnFetchAction } from 'app/ducks/whats-on';
import { selectWhatsOnResultsFromCache } from 'app/selectors/whats-on';
import WhatsOnPageWhatsOnSection from './whats-on-page-whats-on-section';

function mapStateToProps(state) {
    return {
        whatsOnResults: (timeRange) => selectWhatsOnResultsFromCache(state, timeRange, ALL_ENTITIES, false)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        initiateWhatsOnFetch: (timeRange) => dispatch(initiateWhatsOnFetchAction(timeRange, ALL_ENTITIES, false))
    };
}

const VisibleWhatsOnPageWhatsOnSection = connect(
    mapStateToProps,
    mapDispatchToProps
)(WhatsOnPageWhatsOnSection);

export default VisibleWhatsOnPageWhatsOnSection;
