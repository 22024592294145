/* eslint-disable require-jsdoc */

export function selectWhatsOnPageTimeRange({ whatsOn }) {
    return whatsOn.whatsOnPageTimeRange;
}

export function selectWhatsOnResultsFromCache({ whatsOn }, timeRange, entityTypes, comingUp, isFeatured) {
    const { cache } = whatsOn;

    const cacheHit = cache.find((candidateCacheHit) => {
        return candidateCacheHit.timeRange === timeRange
            && candidateCacheHit.entityTypes === entityTypes
            && candidateCacheHit.comingUp === comingUp
            && candidateCacheHit.isFeatured === isFeatured;
    });

    if (cacheHit) {
        return cacheHit.results;
    }
}
