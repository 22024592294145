/* eslint-disable require-jsdoc */
import { connect } from 'react-redux';
import ContactUsForm from './contact-us-form';
import {
    selectFormFields,
    selectIsBusy,
    selectSubmitted,
    selectSubmittedSuccessfully
} from 'app/selectors/contact-us-form';
import {
    setContactUsFormFieldsAction,
    submitContactUsFormAction
} from 'app/ducks/contact-us-form';

function mapStateToProps(state) {
    return {
        formFields: selectFormFields(state),
        submitted: selectSubmitted(state),
        isBusy: selectIsBusy(state),
        submittedSuccessfully: selectSubmittedSuccessfully(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setFormFields: (formFields) => dispatch(setContactUsFormFieldsAction(formFields)),
        submitForm: (formData, captchaToken) => dispatch(submitContactUsFormAction(formData, captchaToken))
    };
}

const VisibleContactUsForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactUsForm);

export default VisibleContactUsForm;
