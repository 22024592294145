/* eslint-disable require-jsdoc */
import { getPageDataAction } from 'app/ducks/page';
import selectPage from 'app/selectors/page';
import { isAbsoluteUrl, normalizePath } from 'app/utilities/url';
import { LOCATION_CHANGE, replace } from 'redux-first-history';
import { startedRoutingAction, stoppedRoutingAction } from 'app/ducks/status';

// Handle custom URL redirects before fetch the page data from API
function resolveRedirect(customUrls, action) {
    const { payload } = action;

    const path = normalizePath(payload.location.pathname);

    const redirectTo = customUrls[path];
    if (redirectTo) {
        // support absolute path redirects
        if (isAbsoluteUrl(redirectTo)) {
            window.location.replace(redirectTo);
        }

        return replace(redirectTo);
    }

    return null;
}

function createPreRouteMiddleware() {
    return ({ dispatch, getState }) => (next) => (action) => {
        const { payload, type } = action;

        if (type && type === LOCATION_CHANGE) {
            const state = getState();
            // Check if we need to do redirects
            const redirectAction = resolveRedirect(state.customUrls, action);
            if (redirectAction) {
                dispatch([redirectAction]);

                return;
            }

            const location = payload && payload.location ? payload.location : { pathname: '' };
            const pathname = location.pathname;

            const page = selectPage(state, pathname);
            if (page) {
                dispatch(stoppedRoutingAction());

                next(action);
            } else {
                dispatch([
                    startedRoutingAction(),
                    getPageDataAction(location)
                ]);
            }
        } else {
            next(action);
        }
    };
}

const preRouteMiddleware = createPreRouteMiddleware();

export default preRouteMiddleware;
