import Banner from 'app/components/partials/banner';
import BookNowForm from 'app/components/partials/book-now-form';
import ContentBlocks from 'app/components/content-blocks/index';
import PropTypes from 'prop-types';
import React from 'react';

function BookNow(props) {
    const {
        blocks,
        name: title,
        parent: parentSlug
    } = props.data;

    return (
        <div className="content-page">
            <Banner
                title={title}
                parentSlug={parentSlug}
            />
            <BookNowForm />
            <ContentBlocks data={blocks} />
        </div>
    );
}

BookNow.propTypes = {
    data: PropTypes.object.isRequired
};

export default BookNow;
