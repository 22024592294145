import ErrorPageImage from 'static/images/error-page.png';
import Helmet from 'react-helmet';
import ImageGallerySingle from 'app/components/partials/image-gallery-single';
import React from 'react';

function Error() { // eslint-disable-line require-jsdoc
    const item = {
        creditline: '<h1>Something went wrong.</h1><p>It looks like we couldn’t find the page you were looking for. Feel free to <a href="mailto:info@maritimemuseum.co.nz">contact us</a> or head straight to our homepage.</p>',
        file: ErrorPageImage,
        url: 'https://www.maritimemuseum.co.nz/',
        buttonText: 'Back to home page'
    };

    return (
        <main className="page" role="main">
            <Helmet>
                <title>Something went wrong</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="not-found">
                <ImageGallerySingle item={item}/>
            </div>
        </main>
    );
}

export default Error;
