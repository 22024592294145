import formatContent from 'app/utilities/format-content';
import Icon from './icon';
import PropTypes from 'prop-types';
import React from 'react';

function EventDetails({ data }) { // eslint-disable-line require-jsdoc
    const {
        intro,
        duration,
        location,
        tickets_link: ticketsLink,
        starts_at_summary: startsAtSummary,
        price_summary: priceSummary
    } = data;

    const visibleDates = startsAtSummary;
    const visiblePrice = priceSummary;

    if (intro) {
        return (
            <section className="constrain-width || content-section || text-content-block">
                <div className="content-section-inner has-aside">
                    {intro && (
                        <main
                            className="content-section-main || rte-content || rich-editor-content"
                            dangerouslySetInnerHTML={{ __html: formatContent(intro) }}
                        />
                    )}
                    <aside
                        className="content-section-aside || rte-content"
                    >
                        {visibleDates && (
                            <div className="event-details-aside-row">
                                <Icon className="event-details-aside-row-icon" name="dates" />
                                <p className="event-details-aside-row-text">
                                    {visibleDates}
                                </p>
                            </div>
                        )}
                        {duration && (
                            <div className="event-details-aside-row">
                                <Icon className="event-details-aside-row-icon" name="duration" />
                                <p className="event-details-aside-row-text">
                                    {duration}
                                </p>
                            </div>
                        )}
                        {location && (
                            <div className="event-details-aside-row">
                                <Icon className="event-details-aside-row-icon" name="location" />
                                <p className="event-details-aside-row-text">
                                    {location}
                                </p>
                            </div>
                        )}
                        {visiblePrice && (
                            <div className="event-details-aside-row">
                                <Icon className="event-details-aside-row-icon" name="price" />
                                <p className="event-details-aside-row-text">
                                    {visiblePrice}
                                </p>
                            </div>
                        )}
                        {ticketsLink && (
                            <a className="button is-active || event-details-aside-book-now" href={ticketsLink}>Book now</a>
                        )}
                    </aside>
                </div>
            </section>
        );
    }

    return null;
}


EventDetails.propTypes = {
    data: PropTypes.object.isRequired
};

export default EventDetails;
