/* eslint-disable require-jsdoc */

export function selectIsBusy({ search }) {
    const { cache, activeKeywords } = search;

    return !cache.hasOwnProperty(activeKeywords);
}

export function selectIsLoading({ search }) {
    return search.isLoading;
}

export function selectSearchKeywords({ search }) {
    return search.keywords;
}

export function selectSearchActiveKeywords({ search }) {
    return search.activeKeywords;
}

export function selectSearchResults({ search }) {
    const { cache, activeKeywords } = search;

    if (!activeKeywords) {
        return {};
    }

    if (cache.hasOwnProperty(activeKeywords)) {
        return cache[activeKeywords];
    }

    return {};
}

export function selectSearchDialogIsActive({ search }) {
    return search.searchDialogIsActive;
}

export function selectAnimateSearchResults({ search }) {
    return search.animateSearchResults;
}

export function selectSearchCache({ search }) {
    return search.cache;
}
