import { getLocal, setLocal } from './local-storage';

export const NOTIFICATIONS_KEY = 'notifications';

export const filterNotifications = (notifications = null) => {
    if (!notifications || notifications.length === 0) return [];

    const filteredNotifications = notifications.reduce((accumulator, currentItem) => {
        const {
            description,
            is_published: isPublished,
            notification_type: type,
            title,
            url,
        } = currentItem.attributes;

        if (isPublished) {
            const newItem = {
                id: parseInt(currentItem.id),
                type,
                title,
                description,
                url,
                isPublished,
                isVisible: true
            };

            accumulator.push(newItem);
        }

        return accumulator;
    }, []);

    return filteredNotifications;
};

export const updateNotifications = (notifications) => {
    if (!notifications) return false;

    const currentNotifications = getLocal(NOTIFICATIONS_KEY);
    const newNotifications = [];

    let hasUnseenNotification = false;

    if (currentNotifications) {
        // Check if state notifications are in local storage
        notifications.forEach((notification) => {
            // Use to update new notification content regardless
            const newNotification = notification;

            currentNotifications.forEach((localItem) => {
                if (localItem.id === notification.id) {
                    // Persist the visibility if already in local
                    newNotification.isVisible = localItem.isVisible;

                    if (newNotification.isVisible) {
                        hasUnseenNotification = true;
                    }

                    // Force show if fields have changed
                    if (localItem.title !== notification.title
                        || localItem.url !== notification.url
                        || localItem.type !== notification.type) {
                        hasUnseenNotification = true;
                        newNotification.isVisible = true;
                    }
                }
            });

            // Update regardless
            newNotifications.push(newNotification);
        });

        // Save the notifications to localStorage
        setLocal(NOTIFICATIONS_KEY, newNotifications);
    } else {
        // Notifications not defined save the current from props
        setLocal(NOTIFICATIONS_KEY, notifications);

        hasUnseenNotification = true;
    }

    return hasUnseenNotification;
};

/**
 * Hide a specific notification by key value
 * @param  {number} id             - The id of the notification (Matches the CMS ID value from API)
 * @return {object} notifications  - The updated notifications object
 */
export const hideNotification = (id = 'all') => {
    const notifications = getLocal(NOTIFICATIONS_KEY);

    // Toggle item isVisible attr using id
    notifications.forEach((item) => {
        if (id === 'all' || item.id === id) {
            item.isVisible = false;
        }
    });

    // Save the notifications to localStorage
    setLocal(NOTIFICATIONS_KEY, notifications);

    return notifications;
};
