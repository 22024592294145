import PropTypes from 'prop-types';
import React from 'react';
import WhatsOn from './whats-on';

class WhatsOnPageComingUpSection extends React.Component {
    constructor(props) {
        super(props);
    }

    // Life cycle
    componentDidMount() {
        this.props.initiateWhatsOnFetch();
    }

    render() {
        const { whatsOnResults } = this.props;

        if (whatsOnResults && whatsOnResults.length) {
            return (
                <div>
                    <section className="whats-on || content-section || constrain-width no-pad">
                        <header className="whats-on-header || constrain-width">
                            <h3>Coming up at Maritime Museum</h3>
                        </header>
                        <WhatsOn items={whatsOnResults} />
                    </section>
                </div>
            );
        }

        return null;
    }
}

WhatsOnPageComingUpSection.propTypes = {
    initiateWhatsOnFetch: PropTypes.func.isRequired,
    whatsOnResults: PropTypes.array
};

export default WhatsOnPageComingUpSection;
