import { EntityLink } from 'rfa-react-core';
import Icon from 'app/components/partials/icon';
import PaginationIndication from 'app/components/partials/pagination-indication';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import React, { useEffect, useRef, useState } from 'react';


function renderTile(item, index) { // eslint-disable-line require-jsdoc
    const {
        name,
        thumbnail,
        landscape_thumbnail: landscapeThumbnail
    } = item.data;

    return (
        <article className="tile" key={index}>
            <EntityLink
                item={item}
                promoterBuName="Maritime Museum"
            >
                <div className="tile-image" style={{ backgroundImage: `url(${thumbnail || landscapeThumbnail})` }} />
                <h3 className="tile-description">
                    {name}
                </h3>
                <div className="tile-read-more || text-link">
                    Read more <Icon name="right-arrow" />
                </div>
            </EntityLink>
        </article>
    );
}

const ContentSet = ({ items, rows, title }) => {
    const [currentItem, setCurrentItem] = useState(0);
    const [viewMore, setViewMore] = useState(false);
    const imageCarouselRef = useRef();

    // Render
    const renderCarousel = () => (
        <Slider
            className=""
            ref={imageCarouselRef}
            {...IMAGE_CAROUSEL_CONFIG}
            beforeChange={(...args) => setCurrentItem(args[1])}
        >
            {items.map(renderTile)}
        </Slider>
    );

    const viewMoreBtnClickHandler = () => setViewMore(!viewMore);

    const renderGrid = () => {
        const displayRows = rows || 1;
        const itemsPerLine = 3;
        const shouldDisplayViewMoreBtn = displayRows * itemsPerLine < items.length;

        let displayItems = items;

        if (!viewMore) {
            const displayCount = Math.min(displayRows * itemsPerLine, displayItems.length);
            displayItems = items.slice(0, displayCount);
        }

        return (
            <>
                {displayItems.map(renderTile)}
                {shouldDisplayViewMoreBtn &&
                    <button
                        className="button alternative || view-more"
                        onClick={viewMoreBtnClickHandler}
                    >{viewMore ? 'View less' : 'View more'}</button>
                }
            </>
        );
    };

    const initialiseState = () => {
        setCurrentItem(0);
        setViewMore(false);
    };

    useEffect(() => {
        if (imageCarouselRef && imageCarouselRef.current) {
            imageCarouselRef.current.slickGoTo(0);
            initialiseState();
        }
    }, [items, title]);

    return (
        <section className="tile-block || content-section">
            {title && (
                <header className="tile-block-title || constrain-width">
                    <h2>{title}</h2>
                </header>
            )}
            <div className="tile-block-grid-wrapper || constrain-width no-pad">
                {renderGrid()}
            </div>
            <div className="tile-block-carousel-wrapper || constrain-width no-pad">
                {renderCarousel()}
            </div>
            <div className="tile-block-pagination || constrain-width">
                <PaginationIndication
                    total={items.length}
                    currentIndex={currentItem}
                    fixedWidth
                />
            </div>
        </section>
    );
};

ContentSet.defaultProps = {
    rows: 1
};

ContentSet.propTypes = {
    items: PropTypes.array.isRequired,
    title: PropTypes.string,
    rows: PropTypes.string
};

const IMAGE_CAROUSEL_CONFIG = {
    dots: false,
    draggable: true,
    infinite: false,
    speed: 200,
    swipe: true,
    arrows: false,

    // Over 800px.
    slidesToShow: 3,
    slidesToScroll: 3,
    centerMode: false,

    responsive: [
        {
            breakpoint: 800, // 800px and under.
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                centerMode: false
            }
        },
        {
            breakpoint: 500, // 500px and under.
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '15px',
                adaptiveHeight: true
            }
        }
    ]
};

export default ContentSet;
