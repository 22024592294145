import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const NewsletterSignup = ({
    border,
    inline,
}) => {
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [error, setError] = useState('');

    /**
     * Stop submit if form is invalid
     */
    function handleSubmit(e) {
        if (!validate()) {
            e.preventDefault();
        }
    }

    /**
     * Validate form inputs
     */
    function validate() {
        if (email !== confirmEmail) {
            setError('Email addresses must match');

            return false;
        }

        setError('');

        return true;
    }

    /**
     * Render inputs
     */
    function renderInputs() {
        return (
            <div className="form-fields">
                <input
                    className="form-control || input"
                    type="text"
                    required
                    placeholder="First name"
                    name="FNAME"
                />

                <input
                    className="form-control || input"
                    type="text"
                    required
                    placeholder="Last name"
                    name="LNAME"
                />

                <input
                    className="form-control || input"
                    type="email"
                    required
                    placeholder="Email address"
                    name="EMAIL"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                {/* Mailchimp doesn't use this field, this is just for input validation on our side */}
                <input
                    className="form-control || input"
                    type="email"
                    required
                    placeholder="Confirm email address"
                    name="confirm_email"
                    value={confirmEmail}
                    onChange={(e) => setConfirmEmail(e.target.value)}
                />
            </div>
        );
    }

    /**
     * Render any existing error message
     */
    function renderError() {
        if (error) {
            return <div className="newsletter-signup-message || form-control-alert danger">{error}</div>;
        }

        return null;
    }

    /**
     * Render submit button
     */
    function renderSubmitButton() {
        return (
            <button
                type="submit"
                className="button alternative"
            >
                Subscribe
            </button>
        );
    }

    return (
        <div className="newsletter-signup">
            <form
                className={classnames('newsletter-signup-form || form', { 'no-border': !border, 'inline': inline })}
                action="//maritimemuseum.us5.list-manage.com/subscribe/post?u=8eb947bda0cee54bf08928e9c&id=416e1314a2"
                method="post"
                target="_blank"
                onSubmit={handleSubmit}
            >
                {renderInputs()}
                {renderError()}
                {renderSubmitButton()}
            </form>
        </div>
    );
};

NewsletterSignup.propTypes = {
    border: PropTypes.bool.isRequired,
    inline: PropTypes.bool
};

export default NewsletterSignup;
