import Banner from 'app/components/partials/banner';
import ContentBlocks from 'app/components/content-blocks/index';
import PropTypes from 'prop-types';
import React from 'react';
import WhatsOnPageComingUpSection from 'app/components/partials/whats-on-page-coming-up-section.container';
import WhatsOnPageFeaturedSection from 'app/components/partials/whats-on-page-featured-section.container';
import WhatsOnPageWhatsOnSection from 'app/components/partials/whats-on-page-whats-on-section.container';

// eslint-disable-next-line require-jsdoc
function WhatsOn(props) {
    const {
        blocks,
        name: title,
        parent: parentSlug
    } = props.data;

    return (
        <div className="content-page">
            <Banner
                title={title}
                parentSlug={parentSlug}
            />
            <WhatsOnPageFeaturedSection />
            <WhatsOnPageWhatsOnSection />
            <WhatsOnPageComingUpSection />
            <ContentBlocks data={blocks} />
        </div>
    );
}

WhatsOn.propTypes = {
    data: PropTypes.object.isRequired
};

export default WhatsOn;
