import ImageGalleryDouble from 'app/components/partials/image-gallery-double';
import ImageGallerySingle from 'app/components/partials/image-gallery-single';
import PropTypes from 'prop-types';
import React from 'react';

class Gallery extends React.Component {
    render() {
        const { items } = this.props;

        if (items.length === 1) {
            return (
                <section className="image-gallery">
                    <ImageGallerySingle item={items[0]} />
                </section>
            );
        }

        if (items.length % 2 === 0) {
            return (
                <section className="image-gallery">
                    <ImageGalleryDouble items={items} />
                </section>
            );
        }

        return null;
    }
}

Gallery.propTypes = {
    items: PropTypes.array.isRequired
};

export default Gallery;
