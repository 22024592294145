export const INITIAL_STATE = {
    menuIsActive: false,
    navigationItemOpen: null
};

// Actions
export const SET_MENU_IS_ACTIVE = 'rfa-maritime-website/menu/SET_MENU_IS_ACTIVE';
export const SET_NAVIGATION_ITEM_OPEN = 'rfa-maritime-website/menu/SET_NAVIGATION_ITEM_OPEN';

// Action Creators
export const setMenuIsActiveAction = (menuIsActive) => ({
    type: SET_MENU_IS_ACTIVE,
    menuIsActive
});

export const setNavigationItemOpenAction = (navigationItemOpen) => ({
    type: SET_NAVIGATION_ITEM_OPEN,
    navigationItemOpen
});

// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_MENU_IS_ACTIVE:
            return setMenuIsActive(state, action.menuIsActive);
        case SET_NAVIGATION_ITEM_OPEN:
            return setNavigationItemOpen(state, action.navigationItemOpen);
        default:
            return state;
    }
};

function setMenuIsActive(state, menuIsActive) {
    return {
        ...state,
        menuIsActive
    };
}

function setNavigationItemOpen(state, navigationItemOpen) {
    return {
        ...state,
        navigationItemOpen
    };
}
