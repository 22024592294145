/**
 * Format the entity type
 *
 * @param  {string} type currently stored as plural from CMS
 *
 * @return {string} type re-formatted as single
 */
export function entityType(type) {
    switch (type) {
        case 'pages':
            return 'page';
        case 'stories':
            return 'story';
        case 'sailings':
            return 'sailing';
        case 'events':
            return 'event';
        case 'exhibitions':
            return 'exhibition';
        case 'educations':
            return 'education';
        default:
            return type;
    }
}

/**
 * Format the search object data
 *
 * @param  {object} item with the attributtes
 *
 * @return {object} item with data
 */
export function searchResult(item) {
    const type = entityType(item.type);

    return {
        ...item,
        type,
        data: item.attributes
    };
}

export const JSON_SPACE = 4;

export function getJsonStr(jsonObject) {
    return JSON.stringify(jsonObject, null, JSON_SPACE);
}
