import * as format from 'app/utilities/format';
import classnames from 'classnames';
import { EntityLink } from 'rfa-react-core';
import Loader from 'app/components/partials/loader';
import PropTypes from 'prop-types';
import truncate from 'app/utilities/truncate';
import React, { Component } from 'react';

const DESCRIPTION_CHARACTER_LIMIT = 55;

class EducationWhatsOnSection extends Component {
    constructor(props) {
        super(props);
    }

    // Life cycle
    componentDidMount() {
        this.props.fetchEducationList();
    }

    renderItems(items) {
        if (items) {
            return (
                <div className="whats-on-inner || constrain-width no-pad">
                    {items.map((item, index) => {
                        const { name, cost, level, thumbnail_image: image } = item.attributes;
                        const itemTransformed = format.searchResult(item);

                        return (
                            <article className={classnames('whats-on-tile', { 'is-featured': items.length === 1 })} key={index}>
                                <EntityLink
                                    item={itemTransformed}
                                    promoterBuName="Maritime Museum"
                                    className="whats-on-tile-inner"
                                >
                                    <img className="whats-on-tile-image" src={image} alt={name} />
                                    <div className="whats-on-tile-content">
                                        <span className="whats-on-tile-category">Education</span>
                                        <h3 className="whats-on-tile-title">{truncate(name, DESCRIPTION_CHARACTER_LIMIT)}</h3>
                                        <div className="whats-on-tile-details">
                                            <span className="date">{level}</span>
                                            <span className="price">{cost}</span>
                                        </div>
                                    </div>
                                </EntityLink>
                            </article>
                        );
                    })}
                </div>
            );
        }

        return <Loader />;
    }

    render() {
        if (this.props.educationList.length) {
            return (
                <div>
                    <section className="whats-on || content-section || constrain-width no-pad">
                        <header className="whats-on-header || constrain-width">
                            <h2>Our programmes</h2>
                        </header>
                        {this.renderItems(this.props.educationList)}
                    </section>
                </div>
            );
        }

        return null;
    }
}

EducationWhatsOnSection.propTypes = {
    fetchEducationList: PropTypes.func.isRequired,
    educationList: PropTypes.array
};

export default EducationWhatsOnSection;
