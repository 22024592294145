import env from './env';

export const { API_BASE_URL, CLIENT_ID, CLIENT_SECRET } = env;

const APP_ID = 'maritime';

export const ENDPOINTS = {
    CONTACT_US: `${API_BASE_URL}/${APP_ID}/get-in-touch`,
    EVENTS: (slug) => `${API_BASE_URL}/${APP_ID}/events?slug=${encodeURIComponent(slug)}`,
    EDUCATIONS: (slug) => `${API_BASE_URL}/${APP_ID}/educations?slug=${encodeURIComponent(slug)}`,
    EXHIBITIONS: (slug) => `${API_BASE_URL}/${APP_ID}/exhibitions?slug=${encodeURIComponent(slug)}`,
    NOTIFICATIONS: `${API_BASE_URL}/${APP_ID}/notifications`,
    PAGES: (slug, parent = '') => `${API_BASE_URL}/${APP_ID}/pages?slug=${encodeURIComponent(slug)}&parent=${encodeURIComponent(parent)}`,
    SAILINGS: (slug) => `${API_BASE_URL}/${APP_ID}/sailings?slug=${encodeURIComponent(slug)}`,
    STORIES: (slug) => `${API_BASE_URL}/${APP_ID}/stories?slug=${encodeURIComponent(slug)}`,
    TOKEN: `${API_BASE_URL}/oauth/token`,
    SEARCH: (keywords, page = 1) => `${API_BASE_URL}/${APP_ID}/search?keywords=${encodeURIComponent(keywords)}&page=${page}`,
    WHATS_ON: `${API_BASE_URL}/${APP_ID}/search/whats-on`,
    WHATS_ON_EDUCATION: `${API_BASE_URL}/${APP_ID}/educations`,
    GLOBAL_FIELDS: `${API_BASE_URL}/${APP_ID}/global-fields`,
    SITEMAP: `${API_BASE_URL}/${APP_ID}/sitemap`,
    CUSTOM_URLS: `${API_BASE_URL}/${APP_ID}/custom-urls`,
};

export const RESPONSE = {
    OK: 200,
    BAD_REQUEST: 400,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500
};

export const TIMEOUT = 0;
