import { dataLayerPush } from 'app/utilities/gtm';
import Icon from 'app/components/partials/icon';
import PaginationIndication from 'app/components/partials/pagination-indication';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';

class ImageCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = ImageCarousel.createInitialState();
        this.imageCarousel = undefined;
    }

    static createInitialState() {
        return {
            currentItem: 0
        };
    }

    // Lifecycle
    UNSAFE_componentWillReceiveProps(nextProps) { // eslint-disable-line react/no-deprecated
        if (this.imageCarousel && nextProps.data !== this.props.data) {
            this.imageCarousel.slickGoTo(0);
            this.setState(ImageCarousel.createInitialState);
        }
    }


    // Handler
    handleNext() {
        const { data } = this.props;
        const { currentItem } = this.state;

        /* ↓ Tracking gallery scroll events */
        dataLayerPush({
            id: 'micro_conversion',
            type: 'engaging_content',
            action: 'Carousel Click',
            text: data[currentItem].title
        });
        /* ↑ Tracking gallery scroll events */

        this.imageCarousel.slickNext();
    }

    handlePrevious() {
        const { data } = this.props;
        const { currentItem } = this.state;

        /* ↓ Tracking gallery scroll events */
        dataLayerPush({
            id: 'micro_conversion',
            type: 'engaging_content',
            action: 'Carousel Click',
            text: data[currentItem].title
        });
        /* ↑ Tracking gallery scroll events */

        this.imageCarousel.slickPrev();
    }


    // Render
    renderCarousel() {
        const { data } = this.props;

        return (
            <Slider
                ref={(me) => { this.imageCarousel = me; }}
                {...IMAGE_CAROUSEL_CONFIG}
                beforeChange={(prevCurrentItem, nextCurrentItem) => {
                    this.setState({ currentItem: nextCurrentItem });
                }}
            >
                {data.map(({ file, title, subtitle, link }, index) => {
                    return (
                        <div key={index} className="image-carousel-item">
                            {link ? <a href={link}>{this.renderImage(file, index)}</a> : this.renderImage(file, index)}
                            <div className="image-carousel-content">
                                <h2 className="title || primary">{title}</h2>
                                <p className="subtitle">{subtitle}</p>
                            </div>
                        </div>
                    );
                })}
            </Slider>
        );
    }

    renderImage(file, index) {
        return (
            <div
                className="image-carousel-media"
                style={{ backgroundImage: `url(${file})` }}
            >
                <img className="image-carousel-img" src={file} alt="title" />
                <span className="image-carousel-screen-reader-text">{`Carousel slide ${index + 1} image`}</span>
            </div>
        );
    }


    render() {
        const { currentItem } = this.state;
        const { data } = this.props;

        return (
            <section className="image-carousel || content-section || constrain-width no-pad">
                <div className="image-carousel-inner">
                    {this.renderCarousel()}
                    {data.length > 1 &&
                            <div className="image-carousel-button-group">
                                <button className="carousel-control previous" onClick={this.handlePrevious.bind(this)}>
                                    <Icon name="dropdown" title="Previous" />
                                </button>
                                <button className="carousel-control next" onClick={this.handleNext.bind(this)}>
                                    <Icon name="dropdown" title="Next" />
                                </button>
                            </div>
                    }
                    <div className="image-carousel-pagination">
                        <PaginationIndication
                            total={data.length}
                            currentIndex={currentItem}
                            fixedWidth
                        />
                    </div>
                </div>
            </section>
        );
    }
}

ImageCarousel.propTypes = {
    data: PropTypes.array.isRequired
};

const IMAGE_CAROUSEL_CONFIG = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    draggable: true,
    infinite: true,
    pauseOnHover: true,
    speed: 300,
    swipe: true,
    arrows: false,
    responsive: [
        {
            breakpoint: 600,
            settings: {
                autoplay: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                infinite: false,
                centerPadding: '15px',
                speed: 200,
            }
        }
    ]
};

export default ImageCarousel;
