import NewsletterSignupForm from './newsletter-signup-form';
import React from 'react';

class NewsletterSignup extends React.Component {
    render() {
        return (
            <div>
                <h5>Sign up to our newsletter</h5>
                <NewsletterSignupForm
                    border={true}
                    inline
                />
                <p className="privacy-policy">We manage subscriber name and email details in accordance with our <a href="/privacy-policy">Privacy Policy</a></p>
            </div>
        );
    }
}

NewsletterSignup.propTypes = {
};

export default NewsletterSignup;
