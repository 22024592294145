import PropTypes from 'prop-types';
import React, { Component } from 'react';

class StacklaWidget extends Component {
    componentDidMount() {
        if (typeof Stackla !== 'undefined') {
            // Re-build every case on the page
            Stackla.WidgetManager.rebuild(); // eslint-disable-line no-undef
        }
    }

    UNSAFE_componentWillReceiveProps({ id }) { // eslint-disable-line react/no-deprecated
        // Re-build if ID changes
        if (typeof Stackla !== 'undefined' && id !== this.props.id) {
            Stackla.WidgetManager.rebuild(); // eslint-disable-line no-undef
        }
    }

    render() {
        const { hash, id, subtitle, title } = this.props;

        // All of these properties are needed for the embedded JS to work correctly
        const STACKLA_WIDGET_RESERVED_PROPERTIES = {
            className:      'stackla-widget', // This class name is used by the client side script to render the widget
            'data-alias':   'regionalfacilitiesauckland.stackla.com',
            'data-ct':      '',
            'data-hash':    hash,
            'data-id':      id,
            'data-title':   title,
            'data-ttl':     '30'
        };

        return (
            <section className="content-section || constrain-width">
                <header>
                    <h2>{title}</h2>
                    {subtitle && <p>{subtitle}</p>}
                </header>
                <div {...STACKLA_WIDGET_RESERVED_PROPERTIES}></div>
            </section>
        );
    }
}

const WIDGET_TYPES = ['Grid'];

StacklaWidget.defaultProps = {
    type: WIDGET_TYPES[0]
};

StacklaWidget.propTypes = {
    hash: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf(WIDGET_TYPES).isRequired
};

export default StacklaWidget;
