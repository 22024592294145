/* eslint-disable camelcase */

export default {
    SWATCH: {
        items: [
            {
                title: 'Primary',
                colour: 'primary'
            }, {
                title: 'Dark',
                colour: 'dark',
                variations: ['tint-1', 'tint-2']
            }, {
                title: 'Light',
                colour: 'light',
                type: 'outline',
                variations: ['shade-1']
            }
        ]
    },
    TEXT_PROPS: {
        data: {
            content: '<h1>Text only</h1><p>Lorem ipsum dolor sit amet, <strong>consectetur adipiscing elit</strong>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>'
        }
    },
    TEXT_ASIDE_PROPS: {
        data: {
            content: '<h1>Text with aside</h1><p>Lorem ipsum dolor sit amet, <strong>consectetur adipiscing elit</strong>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
            aside: '<h2>Aside content</h2><ul><li>Example list item one.</li><li>Example list item two.</li><li>Example list item three.</li><li>Example list item four.</li></ul>'
        }
    },
    CALL_TO_ACTION_PROPS: {
        data: {
            file: 'https://unsplash.it/540/540',
            content: '<h1>No need to work or prepare.</h1><p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to</p><p><a class="small primary button" href="www.google.com" target="_blank">Button test</a></p>'
        }
    },
    FILE_DOWNLOAD_PROPS: {
        display_name: 'Ports of Auckland Harbour Cruise',
        extension: 'pdf',
        file: 'https://www.maritimemuseum.co.nz/sites/default/files/Ports_of_Auckland_Harbour_Cruise_info_sheet.pdf',
        filename: 'Ports_of_Auckland_Harbour_Cruise_info_sheet.pdf',
        filesize: '123000'
    },
    IMAGE_GALLERY_SINGLE_PROPS: {
        item: {
            creditline: '<h2>No need to prepare</h2><p>It is a long established fact that a reader will be distracted by, the readable content of a page. Duis aute irure dolor in ends.</p>',
            file: 'https://unsplash.it/1080/1080',
            url: 'https://maritime.uat.tauweb.co.nz/',
            buttonText: 'Link to a page'
        }
    },
    IMAGE_GALLERY_DOUBLE_PROPS: {
        items: [
            {
                creditline: '<h2>No need to prepare</h2><p>It is a long established fact that a reader will be distracted by, the readable content of a page. Duis aute irure dolor in ends.</p>',
                file: 'https://unsplash.it/1080/1080',
                url: 'https://maritime.uat.tauweb.co.nz/',
                buttonText: 'Link to a page'
            }, {
                creditline: '',
                file: 'https://unsplash.it/1080/1081',
                url: '',
                buttonText: ''
            }, {
                creditline: '',
                file: 'https://unsplash.it/1080/1082',
                url: '',
                buttonText: ''
            }, {
                creditline: '',
                file: 'https://unsplash.it/1080/1083',
                url: '',
                buttonText: ''
            }
        ]
    },
    CONTENT_SET_PROPS: {
        title: 'You may also like',
        items: [
            {
                id: 'pages-13',
                name: 'Miniature fish trap',
                type: 'page',
                data: {
                    name: 'Miniature fish trap',
                    slug: 'miniature-fish-trap',
                    thumbnail: 'https://unsplash.it/400/300',
                    id: 13
                }
            }, {
                id: 'pages-14',
                name: 'Teapot: Shaw Savill',
                type: 'page',
                data: {
                    name: 'Teapot: Shaw Savill',
                    slug: 'teapot-shaw-savill',
                    thumbnail: 'https://unsplash.it/400/301',
                    id: 14
                }
            }, {
                id: 'pages-15',
                name: 'Pecha Kucha (this is also a loooooong title)',
                type: 'page',
                data: {
                    name: 'Pecha Kucha (this is also a loooooong title)',
                    slug: 'pecha-kucha',
                    thumbnail: 'https://unsplash.it/400/302',
                    id: 15
                }
            }
        ]
    },
    HOMEPAGE_HERO_CAROUSEL_DATA: [
        {
            file: 'https://unsplash.it/1920/1080',
            title: 'Aladdin',
            subtitle: '3 January – 3 March, The Civic, ©Disney',
            link: 'https://www.aucklandlive.co.nz/show/aladdin'
        },
        {
            file: 'https://unsplash.it/1920/1081',
            title: 'MADIBA The Musical',
            subtitle: '25 January — 3 February, Bruce Mason Centre',
            link: 'https://www.aucklandlive.co.nz/show/madiba-the-musical'
        },
        {
            file: 'https://unsplash.it/1920/1082',
            title: 'Pacific Sisters',
            subtitle: 'He Toa Tāera | Fashion Activists — 23 February — 14 July 2019, Auckland Art Gallery',
            link: 'pacific-sisters-he-toa-taera-fashion-activists'
        },
        {
            file: 'https://unsplash.it/1920/1083',
            title: 'Auckland Arts Festival',
            subtitle: '7–24 March, various venues',
            link: 'https://www.aucklandlive.co.nz/event/auckland-arts-festival'
        }, {
            file: 'https://unsplash.it/1920/1084',
            title: 'Carving Water, Painting Voice',
            subtitle: 'New Zealand Maritime Museum, until March 2019',
            link: 'https://www.maritimemuseum.co.nz/carving-water-painting-voice'
        }, {
            file: 'https://unsplash.it/1920/1085',
            title: 'Family fun',
            subtitle: 'Auckland Art Gallery Toi o Tāmaki ',
            link: 'https://www.aucklandartgallery.com/visit/families/family-activities'
        }
    ],
    IMAGE_CAROUSEL_PROPS: {
        data: [
            {
                file: 'https://unsplash.it/1920/1080',
                title: 'No need to work or prepare',
                subtitle: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
                link: 'https://www.aucklandartgallery.com/visit/families/family-activities'
            }, {
                file: 'https://unsplash.it/1920/1081',
                title: 'Another random title',
                subtitle: 'A much shorter subtitle in this case.',
                link: 'https://www.aucklandartgallery.com/visit/families/family-activities'
            }, {
                file: 'https://unsplash.it/1920/1082',
                title: 'No need to work or prepare',
                subtitle: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
            }
        ]
    },
    FAQ_PROPS: {
        items: [
            {
                title: 'Short question?',
                heading: 'FAQ block heading',
                content: '<p>Content <strong>bold</strong> <em>italic</em> <u>underlined</u></p>\r\n'
            }, {
                title: 'Very very very very very very very very very very very very very very very very very long question?',
                heading: 'FAQ block heading',
                content: '<p>Content content content content content content content content content content content content content content content content content content content </p>\r\n'
            }
        ]
    },
    WHATS_ON_PROPS: {
        items: [
            {
                id: 'exhibitions-13',
                name: 'January School Holiday Activities',
                type: 'exhibitions',
                attributes: {
                    name: 'January School Holiday Activities',
                    slug: 'january-school-holiday-activities',
                    landscape_thumbnail: 'https://unsplash.it/205/205',
                    id: 13,
                    price: '$40',
                    start_date: new Date(),
                    end_date: new Date()
                }
            }, {
                id: 'sailings-14',
                name: 'Ted Ashby Heritage Sailing with a very long title that is going to break',
                type: 'sailings',
                attributes: {
                    name: 'Ted Ashby Heritage Sailing with a very long title that is going to break',
                    slug: 'ted-ashby',
                    landscape_thumbnail: 'https://unsplash.it/205/206',
                    id: 14,
                    price: '$25-$95',
                    schedule_blocks: [
                        {
                            time: '15:30:00',
                            isoWeekday: '3'
                        }, {
                            time: '17:30:00',
                            isoWeekday: '6'
                        }
                    ]
                }
            }, {
                id: 'sailings-15',
                name: 'Nautilus heritage sailing',
                type: 'sailings',
                attributes: {
                    name: 'Nautilus heritage sailing',
                    slug: 'nautilus',
                    landscape_thumbnail: 'https://unsplash.it/205/207',
                    id: 15,
                    price: '$25-$95',
                    schedule_blocks: [
                        {
                            time: '10:30:00',
                            isoWeekday: '7'
                        }, {
                            time: '11:00:00',
                            isoWeekday: '7'
                        }
                    ]
                }
            }, {
                id: 'exhibitions-16',
                name: 'Knot Touch',
                type: 'exhibitions',
                attributes: {
                    name: 'Knot Touch',
                    slug: 'knot-touch',
                    landscape_thumbnail: 'https://unsplash.it/205/208',
                    id: 16,
                    price: 'FREE',
                    start_date: '2019-02-12',
                    end_date: null
                }
            }
        ]
    },
    REVIEW_PROPS: {
        data: [
            {
                quote: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt us.',
                source: 'Author, company name',
                stars: '5',
                source_url: 'https://www.google.com'
            }, {
                quote: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt us. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt us.',
                source: 'Author',
                stars: '3'
            }, {
                quote: 'Lorem ipsum dolor sit amet.',
                source: 'Author name',
                stars: '1'
            }
        ]
    },
    VIDEO_PROPS: {
        data: {
            title: 'How to talk like a pirate',
            credit: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
            video_url: 'https://www.youtube.com/watch?v=YvemLgLpO3c'
        }
    },
    STACKLA_PROPS: {
        hash: '5e3889b21c189',
        id: '66906',
        subtitle: 'Stackla content block subtitle',
        title: 'Stackla content block title'
    },
    GLOBAL_FIELDS: {
        'open_time': '08:00:24',
        'default': null,
        'close_time': '20:00:00',
        'last_entry': '12:00:00'
    }

};
