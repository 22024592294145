import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { mergeMap  } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

export const INITIAL_STATE = {
    educationList: []
};

// Actions
export const INITIATE_EDUCATIONS_FETCH = 'rfa-maritime-website/INITIATE_EDUCATIONS_FETCH';
export const EDUCATIONS_FETCH_SUCCESS = 'rfa-maritime-website/EDUCATIONS_FETCH_SUCCESS';

// Action Creators
export const initiateEducationFetchAction = () => ({
    type: INITIATE_EDUCATIONS_FETCH
});


export const educationFetchSuccessAction = (educationList) => ({
    type: EDUCATIONS_FETCH_SUCCESS,
    payload: {
        educationList
    }
});

// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EDUCATIONS_FETCH_SUCCESS:
            return educationFetchSuccess(state, action.payload);
        default:
            return state;
    }
};

function educationFetchSuccess(state, { educationList }) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        educationList
    };
}

// Epic creator
export const createEducationEpic = (educationsEndpoint, formatResults, educationFetchSuccessAction) => {
    return (action$) => {
        return action$.pipe(
            ofType(INITIATE_EDUCATIONS_FETCH),
            mergeMap(() => {
                return fetch(educationsEndpoint)
                    .then((response) => educationFetchSuccessAction(formatResults(response)))
                    .catch((error) => educationFetchSuccessAction(error));
            })
        );
    };
};

// Epics
const educationsEpic = createEducationEpic(
    ENDPOINTS.WHATS_ON_EDUCATION,
    formatResults,
    educationFetchSuccessAction
);

// Helpers
function formatResults(response) { // eslint-disable-line require-jsdoc
    if (response && response.data && response.data.length) {
        return response.data;
    }

    return [];
}

export const epics = combineEpics(educationsEpic);
