import Banner from 'app/components/partials/banner';
import ContentBlocks from 'app/components/content-blocks/index';
import EducationWhatsOnSection from '../partials/education-whats-on-section.container';
import PropTypes from 'prop-types';
import React from 'react';

function Learn(props) { // eslint-disable-line require-jsdoc
    const {
        blocks,
        name: title,
        parent: parentSlug
    } = props.data;

    const firstContentBlock = blocks && blocks.length > 0 && blocks[0];
    const remainingContentBlocks = blocks && blocks.length > 1 && blocks.slice(1);

    return (
        <div className="content-page">
            <Banner
                title={title}
                parentSlug={parentSlug}
            />
            {firstContentBlock && (
                <ContentBlocks data={[firstContentBlock]} />
            )}
            <EducationWhatsOnSection />
            {remainingContentBlocks && (
                <ContentBlocks data={remainingContentBlocks} />
            )}
        </div>
    );
}

Learn.propTypes = {
    data: PropTypes.object.isRequired
};

export default Learn;
