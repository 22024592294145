import ContentBlocks from 'app/components/content-blocks/index';
import HomepageHeroCarousel from 'app/components/partials/homepage-hero-carousel';
import PropTypes from 'prop-types';
import Strip from 'app/components/partials/strip';
import { useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';

const separateIntoHomepageHeroCarouselDataAndBlocks = (blocks) => {
    if (blocks.length > 0 && blocks[0].type === 'herocarousel') {
        return {
            homepageHeroCarouselData: blocks[0].data,
            blocksWithoutInitialHeroCarousel: blocks.slice(1)
        };
    }

    return {
        homepageHeroCarouselData: undefined,
        blocksWithoutInitialHeroCarousel: blocks
    };
};

const Home = ({ data }) => {
    const [isStripActive, setIsStripActive] = useState(false);
    const { blocks } = data;

    const globalFields = useSelector((state) => state.globalFields);

    const heroCarouselRef = useRef(null);

    const { homepageHeroCarouselData, blocksWithoutInitialHeroCarousel } = separateIntoHomepageHeroCarouselDataAndBlocks(blocks);

    const checkHeroCarouselInView = () => {
        const heroCarouselOffset = heroCarouselRef.current.getBoundingClientRect();
        if (heroCarouselOffset.top < 0 && heroCarouselOffset.bottom < 0) return setIsStripActive(false);

        setIsStripActive(true);
    };

    useEffect(() => {
        checkHeroCarouselInView();
        window.addEventListener('scroll', checkHeroCarouselInView);

        return () => window.removeEventListener('scroll', checkHeroCarouselInView);
    }, []);

    return (
        <div className="content-page">
            {homepageHeroCarouselData && <HomepageHeroCarousel ref={heroCarouselRef} data={homepageHeroCarouselData} globalFields={globalFields}/>}
            <ContentBlocks data={blocksWithoutInitialHeroCarousel} />
            <Strip isActive={isStripActive} />
        </div>
    );
};

Home.propTypes = {
    data: PropTypes.object.isRequired
};

export default Home;
