/* eslint-disable no-magic-numbers */
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { trackSearch } from 'app/utilities/gtm';
import { combineEpics, ofType } from 'redux-observable';
import { delay, mergeMap } from 'rxjs/operators';
import { from, merge, of } from 'rxjs';
import {
    selectSearchCache,
    selectSearchKeywords
} from 'app/selectors/search';

export const INITIAL_STATE = {
    cache: {},
    keywords: '',
    activeKeywords: '', // Keywords for which the search results are currently showing.
    searchDialogIsActive: false,
    animateSearchResults: false,
    isLoading: false,
};

// Actions
export const SET_SEARCH_DIALOG_IS_ACTIVE = 'rfa-maritime-website/search/SET_SEARCH_DIALOG_IS_ACTIVE';
export const INITIATE_SEARCH_RESULT_FETCH = 'rfa-maritime-website/search/INITIATE_SEARCH_RESULT_FETCH';
export const SET_SEARCH_KEYWORDS = 'rfa-maritime-website/search/SET_SEARCH_KEYWORDS';
export const SET_SEARCH_ACTIVE_KEYWORDS = 'rfa-maritime-website/search/SET_SEARCH_ACTIVE_KEYWORDS';
export const SEARCH_RESULT_FETCH_SUCCESS = 'rfa-maritime-website/search/SEARCH_RESULT_FETCH_SUCCESS';
export const SET_ANIMATE_SEARCH_RESULTS = 'rfa-maritime-website/search/SET_ANIMATE_SEARCH_RESULTS';
export const SET_SEARCH_LOADING = 'rfa-maritime-website/search/SET_SEARCH_LOADING';

// Action Creators
export const setSearchDialogIsActiveAction = (searchDialogIsActive) => ({
    type: SET_SEARCH_DIALOG_IS_ACTIVE,
    searchDialogIsActive
});

export const initiateSearchResultFetchAction = (keywords, page) => ({
    type: INITIATE_SEARCH_RESULT_FETCH,
    keywords,
    page,
});

export const setSearchKeywordsAction = (keywords) => ({
    type: SET_SEARCH_KEYWORDS,
    keywords
});

export const setSearchActiveKeywordsAction = (activeKeywords) => ({
    type: SET_SEARCH_ACTIVE_KEYWORDS,
    activeKeywords
});

export const searchResultFetchSuccessAction = (keywords, searchResults, page) => ({
    type: SEARCH_RESULT_FETCH_SUCCESS,
    payload: {
        keywords,
        searchResults,
        page
    }
});

export const setAnimateSearchResultsAction = (animateSearchResults) => ({
    type: SET_ANIMATE_SEARCH_RESULTS,
    animateSearchResults
});

export const setSearchLoadingAction = (isLoading) => ({
    type: SET_SEARCH_LOADING,
    isLoading,
});

// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_SEARCH_DIALOG_IS_ACTIVE:
            return setSearchDialogIsActive(state, action.searchDialogIsActive);
        case SET_SEARCH_KEYWORDS:
            return setSearchKeywords(state, action.keywords);
        case SET_SEARCH_ACTIVE_KEYWORDS:
            return setSearchActiveKeywords(state, action.activeKeywords);
        case SEARCH_RESULT_FETCH_SUCCESS:
            return searchResultFetchSuccess(state, action.payload);
        case SET_ANIMATE_SEARCH_RESULTS:
            return setAnimateSearchResults(state, action.animateSearchResults);
        case SET_SEARCH_LOADING:
            return setSearchLoading(state, action.isLoading);
        default:
            return state;
    }
};

function setSearchDialogIsActive(state, searchDialogIsActive) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        searchDialogIsActive,
        keywords: '',
        activeKeywords: ''
    };
}

function setSearchKeywords(state, keywords) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        keywords
    };
}

function setSearchActiveKeywords(state, activeKeywords) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        activeKeywords
    };
}

function searchResultFetchSuccess(state, { keywords, searchResults, page = 1 }) { // eslint-disable-line require-jsdoc
    const { data, links, meta } = searchResults;

    const existingCache = state.cache[keywords];

    return {
        ...state,
        cache: {
            ...state.cache,
            [keywords]: {
                // Append new results to existing cache
                results: existingCache && existingCache.results ?
                    [...existingCache.results, ...data] : (data),
                count: meta.result_count,
                hasMore: links.next !== null,
                // tell the search dialog what the next page should be
                nextPage: page + 1,
            }
        }
    };
}

function setAnimateSearchResults(state, animateSearchResults) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        animateSearchResults
    };
}

function setSearchLoading(state, isLoading) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        isLoading
    };
}

// Epic creator
export const createSearchEpic = (searchEndpoint, searchResultFetchSuccessAction) => {
    return (action$, state$) => action$.pipe(
        ofType(INITIATE_SEARCH_RESULT_FETCH),
        mergeMap(({ keywords, page }) => {
            const searchCache = selectSearchCache(state$.value);

            // If the results are already cached or we are doing initial fetch without page param, immediately queue the animation.
            if (!page && searchCache.hasOwnProperty(keywords)) {
                const searchResults = searchCache[keywords];

                /* ↓ Tracking search events */
                trackSearch(keywords, searchResults);
                /* ↑ Tracking search events */

                return merge(...setSearchActiveKeywordsAndQueueAnimation(keywords));
            }

            // Otherwise fetch the search results
            const searchResult$ = from(
                fetch(searchEndpoint(keywords, page))
                    .catch(() => ({
                        // API returns 404 if there are no results
                        // we need to return the expected results format instead
                        data: [],
                        meta: { result_count: 0 },
                        links: {},
                    }))
                    .then((searchResults) => {
                        /* ↓ Tracking search events */
                        trackSearch(keywords, searchResults.data);
                        /* ↑ Tracking search events */

                        return searchResults;
                    })
            );

            return merge(
                // Show loading indicator
                of(setSearchLoadingAction(true)),
                searchResult$.pipe(
                    mergeMap((searchResults) => {
                        return merge(
                            of(searchResultFetchSuccessAction(keywords, searchResults, page)),
                            ...(selectSearchKeywords(state$.value) === keywords ? setSearchActiveKeywordsAndQueueAnimation(keywords) : []),
                            // Hide loading indicator
                            of(setSearchLoadingAction(false))
                        );
                    })
                ),
            );
        })
    );
};

// Epics
const searchEpic = createSearchEpic(
    ENDPOINTS.SEARCH,
    searchResultFetchSuccessAction
);

function setSearchActiveKeywordsAndQueueAnimation(keywords) { // eslint-disable-line require-jsdoc
    return [
        of(setAnimateSearchResultsAction(true)), // Queue animation.
        of(setSearchActiveKeywordsAction(keywords)).pipe(delay(25)), // Set active keywords shortly after the animation is queued.
        of(setAnimateSearchResultsAction(false)).pipe(delay(300)), // Remove the animation after it has finished.
    ];
}

export const epics = combineEpics(searchEpic);
