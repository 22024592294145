import CONTACT_US_FORM_FIELDS from 'config/contact-us-form-fields';
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { mergeMap  } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

const RESET_CONTACT_US_FORM_ON_SUCCESSFULL_SUBMISSION_DELAY = 5000; // 5 seconds

export const INITIAL_STATE = {
    fields: CONTACT_US_FORM_FIELDS.map((formField) => ({
        ...formField,
        value: formField.defaultValue ? formField.defaultValue : '',
        touched: false
    })),
    submitted: false,
    submittedSuccessfully: undefined
};


// Contact Us Form Actions
export const SET_CONTACT_US_FORM_FIELDS = 'rfa-maritime-website/contact-us-form/SET_CONTACT_US_FORM_FIELDS';
export const SUBMIT_CONTACT_US_FORM = 'rfa-maritime-website/contact-us-form/SUBMIT_CONTACT_US_FORM';
export const CONTACT_US_FORM_SUCCESSFULLY_SUBMITTED = 'rfa-maritime-website/contact-us-form/CONTACT_US_FORM_SUCCESSFULLY_SUBMITTED';
export const FAILED_TO_SUBMIT_CONTACT_US_FORM = 'rfa-maritime-website/contact-us-form/FAILED_TO_SUBMIT_CONTACT_US_FORM';
export const RESET_CONTACT_US_FORM = 'rfa-maritime-website/contact-us-form/RESET_CONTACT_US_FORM';

// Contact Us Form Action Creators
export const setContactUsFormFieldsAction = (fields) => ({
    type: SET_CONTACT_US_FORM_FIELDS,
    fields
});

export const submitContactUsFormAction = (formData, captchaToken) => ({
    type: SUBMIT_CONTACT_US_FORM,
    formData,
    captchaToken
});

export const contactUsFormSuccessfullySubmittedAction = () => ({
    type: CONTACT_US_FORM_SUCCESSFULLY_SUBMITTED
});

export const failedToSubmitContactUsFormAction = () => ({
    type: FAILED_TO_SUBMIT_CONTACT_US_FORM
});

export const resetContactUsFormAction = () => ({
    type: RESET_CONTACT_US_FORM
});


// Contact Us Form Reducer
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CONTACT_US_FORM_FIELDS:
            return setContactUsFormFields(state, action.fields);
        case SUBMIT_CONTACT_US_FORM:
            return submitContactUsForm(state);
        case CONTACT_US_FORM_SUCCESSFULLY_SUBMITTED:
            return contactUsFormSuccessfullySubmitted(state);
        case FAILED_TO_SUBMIT_CONTACT_US_FORM:
            return failedToSubmitContactUsForm(state);
        case RESET_CONTACT_US_FORM:
            return INITIAL_STATE;
        default:
            return state;
    }
};


// Contact Us Form Reducer Helpers
function setContactUsFormFields(state, fields) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        fields
    };
}

function submitContactUsForm(state) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: undefined
    };
}

function contactUsFormSuccessfullySubmitted(state) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: true
    };
}

function failedToSubmitContactUsForm(state) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: false
    };
}


// Epic creators
export const createContactUsFormSubmissionEpic = (contactUsEndpoint, contactUsFormSuccessfullySubmittedAction, failedToSubmitContactUsFormAction) => { // eslint-disable-line require-jsdoc
    return (action$) => action$.pipe(
        ofType(SUBMIT_CONTACT_US_FORM),
        mergeMap(({ formData, captchaToken }) => {
            /**
             Uncomment the following return statement to mock successful
             form submission.
             */
            // return new Promise((resolve) => {
            //     setTimeout(() => {
            //         resolve(contactUsFormSuccessfullySubmittedAction());
            //     }, 3000);
            // });

            /**
             Uncomment the following return statement to mock unsuccessful
             form submission.
             */
            // return new Promise((resolve) => {
            //     setTimeout(() => {
            //         resolve(failedToSubmitContactUsFormAction());
            //     }, 3000);
            // });

            return fetch(contactUsEndpoint, null, {
                method: 'POST',
                body: JSON.stringify({
                    ...formData,
                    captchaToken,
                })
            })
                .then(() => {
                    return contactUsFormSuccessfullySubmittedAction();
                })
                .catch(failedToSubmitContactUsFormAction);
        })
    );
};

export const createScheduleContactUsFormResettingEpic = (setTimeout, resetContactUsFormOnSuccessfullSubmissionDelay) => {
    return (action$) => action$.pipe(
        ofType(CONTACT_US_FORM_SUCCESSFULLY_SUBMITTED),
        mergeMap(() => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(resetContactUsFormAction());
                }, resetContactUsFormOnSuccessfullSubmissionDelay);
            });
        })
    );
};


// Epics
const contactUsFormSubmissionEpic = createContactUsFormSubmissionEpic(
    ENDPOINTS.CONTACT_US,
    contactUsFormSuccessfullySubmittedAction,
    failedToSubmitContactUsFormAction
);

const scheduleFormResettingEpic = createScheduleContactUsFormResettingEpic(
    setTimeout,
    RESET_CONTACT_US_FORM_ON_SUCCESSFULL_SUBMISSION_DELAY
);

export const epics = combineEpics(
    contactUsFormSubmissionEpic,
    scheduleFormResettingEpic
);
