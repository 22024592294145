import { getParentNameFromSlug } from 'config/page-parents';
import PropTypes from 'prop-types';
import React from 'react';

class Banner extends React.Component {
    render() {
        const {
            title,
            parentSlug
        } = this.props;

        return (
            <header role="heading" className="banner">
                <div className="constrain-width">
                    <div className="heading-group">
                        <div className="parent-name || heading-5">{parentSlug && getParentNameFromSlug(parentSlug)}</div>
                        <h1 className="title">
                            <div className="title-text">{title}</div>
                        </h1>
                    </div>
                </div>
            </header>
        );
    }
}

Banner.propTypes = {
    title: PropTypes.string.isRequired,
    parentSlug: PropTypes.string
};

export default Banner;
