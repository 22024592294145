import ContentPage from './components/pages/content-page.container';
import ErrorPage from './components/pages/error/error-page';
import Footer from './components/partials/footer';
import Header from './components/partials/header.container';
import NewsletterSignupAndSocialProfileLinks from './components/partials/newsletter-signup-and-social-profile-links';
import NotFoundPage from './components/pages/error/not-found-page';
import React from 'react';
import ScrollToTop from './components/partials/scroll-to-top';
import SearchDialog from './components/partials/search-dialog.container';
import StyleGuidePage from './components/pages/style-guide-page';
import throttleUpdateWhileRouting from './utilities/throttle-update-while-routing';
import { Route, Routes } from 'react-router-dom';

const Router = () => (
    <div className="base">
        <ScrollToTop />
        <Header />
        <SearchDialog />
        <Routes>
            {/* Custom */}
            <Route path="/style-guide" element={<StyleGuidePage />} />
            <Route path="/error-500" element={<ErrorPage />} />
            {/* API Based Routes */}
            <Route path="/" element={<ContentPage />} />
            <Route path="/:slug" element={<ContentPage />} />
            <Route path="/:parent/:slug" element={<ContentPage />} />
            {/* Catch */}
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <NewsletterSignupAndSocialProfileLinks />
        <Footer />
    </div>
);

export default throttleUpdateWhileRouting(Router);
