import { connect } from 'react-redux';
import NotificationBanner from 'app/components/partials/notification-banner';
import {
    selectShowNotification
} from 'app/selectors/notifications';
import {
    updateNotificationAction
} from 'app/ducks/notifications';

const mapStateToProps = (state) => ({
    showNotification: selectShowNotification(state)
});

const mapDispatchToProps = (dispatch) => ({
    updateNotification: (notificationId) => dispatch(updateNotificationAction(notificationId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationBanner);
