import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import customUrls from './custom-urls';
import globalFields from './global-fields';
import menu from './menu';
import newsletterSignup from './newsletter-signup';
import status from './status';
import strip from './strip';
import contactUsForm, { epics as contactUsFormEpics } from './contact-us-form';
import educations, { epics as educationEpic } from './education';
import notifications, { epics as notificationsEpics } from './notifications';
import pages, { epics as pageEpics } from './page';
import search, { epics as searchEpics } from './search';
import whatsOn, { epics as whatsOnEpic } from './whats-on';

export const epics = combineEpics(
    contactUsFormEpics,
    pageEpics,
    searchEpics,
    whatsOnEpic,
    educationEpic,
    notificationsEpics
);

const createRootReducer = (routerReducer) => combineReducers({
    router: routerReducer,
    contactUsForm,
    globalFields,
    menu,
    newsletterSignup,
    pages,
    search,
    status,
    whatsOn,
    educations,
    notifications,
    customUrls,
    strip
});

export default createRootReducer;
