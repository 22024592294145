/**
 * Format the WYSIWYG content
 *
 * @param  {string} content WYSIWYG content
 * @param  {string} primaryHeading primary heading style
 * @param  {string} buttonClass custom button class
 *
 * @return {string} formatted content
 */
export default function(content, primaryHeading = true, buttonClass = 'button primary') {
    // Change the button class
    const formattedContent = content.replace(
        /class="small primary button"/g,
        `class="${buttonClass}"`);

    if (primaryHeading) {
        return formattedContent.replace(
            /<h2>/g,
            '<h2 class="primary">'
        ).replace(
            /<h1>/g,
            '<h2>'
        ).replace(
            /<(\/)h1>/g,
            '</h2>'
        );
    }


    return formattedContent;
}
