import fieldTypes from './field-types';
import { VALIDATOR_TYPES } from 'app/utilities/form-validation';

export default [
    {
        name: 'name',
        label: 'Name',
        required: true,
        placeholder: '',
        fieldType: fieldTypes.INPUT,
        defaultValue: '',
        type: 'text',
        validatorType: VALIDATOR_TYPES.REQUIRED
    },
    {
        name: 'email',
        label: 'Email',
        required: true,
        placeholder: '',
        fieldType: fieldTypes.INPUT,
        defaultValue: '',
        type: 'email',
        validatorType: VALIDATOR_TYPES.REQUIRED_EMAIL
    },
    {
        name: 'phone',
        label: 'Phone',
        required: true,
        placeholder: '',
        fieldType: fieldTypes.INPUT,
        defaultValue: '',
        type: 'tel',
        validatorType: VALIDATOR_TYPES.REQUIRED_PHONE_NUMBER
    },
    {
        name: 'subject',
        label: 'What does your enquiry relate to?',
        required: true,
        placeholder: 'Choose category',
        fieldType: fieldTypes.SELECT,
        defaultValue: undefined,
        options: [
            { value: 'visitor_enquiry_booking_feedback', label: 'Visitor Enquiry / Booking / Feedback' },
            { value: 'library', label: 'Library' },
            { value: 'education', label: 'Education' },
            { value: 'collections', label: 'Collections' },
            { value: 'media', label: 'Media' }
        ],
        validatorType: VALIDATOR_TYPES.REQUIRED
    },
    {
        name: 'message',
        label: 'Message',
        required: true,
        placeholder: '',
        fieldType: fieldTypes.TEXTAREA,
        defaultValue: '',
        validatorType: VALIDATOR_TYPES.REQUIRED
    }
];
