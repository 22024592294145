/**
 * Renders the captcha into the DOM container element.
 *
 * @param  {object} containerElement DOM container element
 * @param  {string} sitekey reCAPTCHA sitekey
 * @param  {function} verifyCallback reCAPTCHA callback
 *
 * @return {object} Abandon and reset callback.
 */
export default function(containerElement, sitekey, verifyCallback) {
    let keepTryingToRender = true,
        widgetId = undefined;

    const abandon = () => {
        keepTryingToRender = false;
    };

    const renderCaptcha = () => {
        widgetId = window.grecaptcha.render(containerElement, {
            sitekey,
            callback: verifyCallback
        });
    };

    const reset = () => {
        if (typeof widgetId !== 'undefined') {
            window.grecaptcha.reset(widgetId);
        }
    };

    const createRenderCaptchaPromise = () => Promise.resolve().then(() => {
        // Keep trying to render the captcha.
        if (keepTryingToRender) {
            renderCaptcha();
        }

        // Otherwise do nothing.
    }).catch(() => {
        return createDelayPromise(1000).then(() => createRenderCaptchaPromise()); // eslint-disable-line no-magic-numbers
    });

    // Start the cycle.
    createRenderCaptchaPromise();

    return { abandon, reset };
}

/**
 * Creates a promise that resolves after the specified delay.
 *
 * @param  {number} timeout Delay (duration in milliseconds).
 *
 * @return {Promise} Promise that resolves after the delay.
 */
function createDelayPromise(timeout) {
    return new Promise((resolve) => {
        setTimeout(resolve, timeout);
    });
}
