/**
 * The function pushes data to the GTM data layer.
 * The dataLayer object is defined in the global scope by the GTM script.
 * @param {Object} data - Data layer record.
 */
export const dataLayerPush = (data) => {
    try {
        dataLayer.push({ // eslint-disable-line no-undef
            event: 'conversionTracking',
            ...data
        });
    } catch (e) {} // eslint-disable-line no-empty
};

export const trackSearch = (keywords, searchResults) => {
    dataLayerPush({
        action: searchResults.length ? 'Successful Search' : 'Unsuccessful Search',
        label: keywords
    });
};
