import { connect } from 'react-redux';
import Header from './header';
import { selectSearchDialogIsActive } from 'app/selectors/search';
import selectStatus from 'app/selectors/status';
import { setSearchDialogIsActiveAction } from 'app/ducks/search';
import {
    fetchNotificationsAction,
    toggleNotificationsAction
} from 'app/ducks/notifications';
import {
    selectIsActive,
    selectNotifications
} from 'app/selectors/notifications';
import { selectMenuIsActive, selectNavigationItemOpen } from 'app/selectors/menu';
import { setMenuIsActiveAction, setNavigationItemOpenAction } from 'app/ducks/menu';

function mapStateToProps(state) { // eslint-disable-line require-jsdoc
    return {
        notifications: selectNotifications(state),
        isNotificationsActive: selectIsActive(state),
        status: selectStatus(state),
        searchDialogIsActive: selectSearchDialogIsActive(state),
        menuIsActive: selectMenuIsActive(state),
        navigationItemOpen: selectNavigationItemOpen(state)
    };
}

function mapDispatchToProps(dispatch) { // eslint-disable-line require-jsdoc
    return {
        fetchNotifications: () => dispatch(fetchNotificationsAction()),
        toggleNotifications: () => dispatch(toggleNotificationsAction()),
        setSearchDialogIsActive: (searchDialogIsActive) => dispatch(setSearchDialogIsActiveAction(searchDialogIsActive)),
        setMenuIsActive: (menuIsActive) => dispatch(setMenuIsActiveAction(menuIsActive)),
        setNavigationItemOpen: (navigationItemOpen) => dispatch(setNavigationItemOpenAction(navigationItemOpen)),
    };
}

const VisibleHeader = connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);

export default VisibleHeader;
