import { clientEnv } from 'config/env';
import { dataLayerPush } from 'app/utilities/gtm';
import iframeResizer from 'iframe-resizer/js/iframeResizer';
import React from 'react';

/*
 * The iframed booking form sends a { type: "MM_SUCCESSFUL_BOOKING" } message
 * to the parent page when the user completes their booking.
 */
if (typeof window !== 'undefined') {
    window.addEventListener('message', (event) => {
        if (event.data && event.data.type === 'MM_SUCCESSFUL_BOOKING') {
            /* ↓ Tracking conversion events */
            dataLayerPush({
                action: 'Successful Booking',
                label: '' // TODO: use the name of the product purchased
            });
            /* ↑ Tracking conversion events */
        }
    }, false);
}

class BookNowForm extends React.Component {
    componentDidMount() {
        if (this.iframe) {
            this.resizerInstance = iframeResizer({
                log: true,
                heightCalculationMethod: 'lowestElement'
            }, this.iframe);
        }
    }

    UNSAFE_componentWillUnmount() {
        if (this.resizerInstance) {
            this.resizerInstance[0].iFrameResizer.close();
        }
    }

    render() {
        return (
            <div className="content-blocks">
                <section className="content-section || constrain-width">
                    <iframe
                        ref={(me) => { this.iframe = me; }}
                        src={clientEnv.CUSTOMLINC_BASE_URL}
                        title="Book now"
                        seamless=""
                        width="100%"
                        scrolling="no"
                        frameBorder="0"
                    />
                </section>
            </div>
        );
    }
}

BookNowForm.propTypes = {
};

export default BookNowForm;
