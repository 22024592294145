/* eslint-disable require-jsdoc */

import moment from 'moment';

export const THIS_WEEK = 'THIS_WEEK';
export const THIS_MONTH = 'THIS_MONTH';
export const COMING_UP = 'COMING_UP';
export const FUTURE = 'FUTURE';

export const timeRanges = [
    THIS_WEEK,
    THIS_MONTH,
    COMING_UP,
    FUTURE
];

export const timeRangeConfiguration = {
    [THIS_WEEK]: {
        start: (today) => today,
        end: (today) => moment(today).endOf('isoWeek') // Includes Sunday
    },
    [THIS_MONTH]: {
        start: (today) => today,
        end: (today) => moment(today).endOf('month')
    },
    [COMING_UP]: {
        start: (today) => moment(today).add(1, 'month').startOf('month'),
        end: (today) => moment(today).add(1, 'month').endOf('month')
    },
    [FUTURE]: {
        start: (today) => today,
        end: (today) => moment(today).add(1, 'year')
    }
};

export const ALL_ENTITIES = 'ALL_ENTITIES';
export const ALL_ENTITIES_BUT_SAILINGS = 'ALL_ENTITIES_BUT_SAILINGS';

export const entityTypesConfiguration = {
    [ALL_ENTITIES]: [
        'sailing',
        'event',
        'exhibition'
    ],
    [ALL_ENTITIES_BUT_SAILINGS]: [
        'event',
        'exhibition'
    ]
};

export function whatsOnRequestBody(timeRange, entityTypes, comingUp, isFeatured) {
    const today = new moment();
    const start = timeRangeConfiguration[timeRange].start(today);
    const end = timeRangeConfiguration[timeRange].end(today);
    const entities = entityTypesConfiguration[entityTypes];

    return {
        startDateTime: start.toISOString(),
        endDateTime: end.toISOString(),
        entities,
        comingUp,
        isFeatured
    };
}
