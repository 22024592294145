import * as format from 'app/utilities/format';
import { EntityLink } from 'rfa-react-core';
import generateWhatsOnItemDateSummary from 'app/utilities/generate-whats-on-item-date-summary';
import Loader from 'app/components/partials/loader';
import PropTypes from 'prop-types';
import truncate from 'app/utilities/truncate';
import React, { useState } from 'react';

/**
 * Filter the items to display depending on the rows configured in the content block
 * @param {array} items
 * @param {number} rows
 * @param {number} itemsPerLine
 * @param {boolean} viewMore
 * @returns {array}
 */
function filterDisplayItems(items, rows, itemsPerLine, viewMore) {
    // Only display the given number of rows if specified and the view more button has not been clicked
    if (rows > 0 && !viewMore) {
        const displayCount = Math.min(rows * itemsPerLine, items.length);

        return items.slice(0, displayCount);
    }

    return items;
}

const DESCRIPTION_CHARACTER_LIMIT = 55;

const WhatsOn = ({ items, rows }) => {
    const [viewMore, setViewMore] = useState(false);
    const itemsPerLine = 2;
    // rows = 0 means display all items
    const shouldDisplayViewMoreBtn = rows > 0 && (rows * itemsPerLine < items.length);
    const displayItems = filterDisplayItems(items, rows, itemsPerLine, viewMore);

    const viewMoreBtnClickHandler = () => setViewMore(!viewMore);

    if (items) {
        return (
            <>
                <div className="whats-on-inner || constrain-width no-pad">
                    {displayItems.map((item, index) => {
                        const type = item.type;
                        const { name, price, landscape_thumbnail: landscapeThumbnail } = item.attributes;
                        const itemTransformed = format.searchResult(item);
                        const date = generateWhatsOnItemDateSummary(itemTransformed);

                        return (
                            <article className="whats-on-tile" key={index}>
                                <EntityLink
                                    item={itemTransformed}
                                    promoterBuName="Maritime Museum"
                                    className="whats-on-tile-inner"
                                >
                                    <div className="whats-on-tile-image" style={{ backgroundImage: `url(${landscapeThumbnail})` }}></div>
                                    <div className="whats-on-tile-content">
                                        <span className="whats-on-tile-category">{type}</span>
                                        <h3 className="whats-on-tile-title">{truncate(name, DESCRIPTION_CHARACTER_LIMIT)}</h3>
                                        <div className="whats-on-tile-details">
                                            <span className="date">{date}</span>
                                            <span className="price">{price}</span>
                                        </div>
                                    </div>
                                </EntityLink>
                            </article>
                        );
                    })}
                </div>
                {shouldDisplayViewMoreBtn &&
                    <div className="view-more">
                        <button
                            className="button alternative || view-more"
                            onClick={viewMoreBtnClickHandler}
                        >{viewMore ? 'View less' : 'View more'}</button>
                    </div>
                }
            </>
        );
    }

    return <Loader />;
};

WhatsOn.propTypes = {
    items: PropTypes.array,
    // pass in rows prop if we want to initially limit the number of rows displayed and render a "View More" button at the end
    rows: PropTypes.string
};

export default WhatsOn;
