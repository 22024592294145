import AUCKLAND_UNLIMITED_LOGO from 'static/images/auckland-unlimited-logo-white.svg';
import classnames from 'classnames';
import footerImage from 'static/images/footer.jpeg';
import { NAVIGATION } from 'config/navigation';
import React from 'react';
import { renderTime12 } from 'app/utilities/time';
import { useSelector } from 'react-redux';
import WomenRefuge from './women-refuge';
import { Link, NavLink } from 'react-router-dom';


function Footer() { // eslint-disable-line require-jsdoc
    const year = new Date().getFullYear();
    const globalFields = useSelector((state) => state.globalFields);
    // eslint-disable-next-line camelcase
    const { open_time, close_time, last_entry } = globalFields;

    return (
        <footer className="footer" style={{ backgroundImage: `url('${footerImage}')` }}>
            <div className="footer-container">
                <div className="footer-inner || constrain-width">
                    <div className="footer-section footer-navigation">
                        <h4 className="footer-section-title">Maritime Museum</h4>
                        <nav>
                            {NAVIGATION.map(({ label, link, href }, index) => {
                                if (href) {
                                    return (
                                        <a
                                            className="link"
                                            href={href}
                                            key={index}>{label}
                                        </a>
                                    );
                                }

                                return (
                                    <NavLink
                                        className={({ isActive }) => classnames('link', {
                                            'is-active': isActive
                                        })}
                                        to={link}
                                        key={index}>{label}
                                    </NavLink>
                                );
                            })}
                        </nav>
                    </div>
                    <div className="footer-section">
                        <h4 className="footer-section-title">Contact us</h4>
                        <p className="footer-section-item">Corner Quay and Hobson streets, Viaduct Harbour, Auckland, NZ</p>
                        <p className="footer-section-item phone">
                            <a
                                className="link"
                                href="tel:+6493730800"
                                /* ↓ Tracking attributes */
                                data-id="macro_conversion"
                                data-type="lead"
                                data-action="phone_click"
                                data-text="+64 9 373 0800"
                                /* ↑ Tracking attributes */
                            >
                                +64 9 373 0800
                            </a>
                        </p>
                        <p className="footer-section-item email">
                            <a
                                className="link"
                                href="mailto:info@maritimemuseum.co.nz"
                                /* ↓ Tracking attributes */
                                data-id="macro_conversion"
                                data-type="lead"
                                data-action="email_click"
                                data-text="info@maritimemuseum.co.nz"
                                /* ↑ Tracking attributes */
                            >
                                info@maritimemuseum.co.nz
                            </a>
                        </p>
                    </div>
                    <div className="footer-section">
                        <div>
                            <h4 className="footer-section-title">Opening hours</h4>
                            <p className="footer-section-item">
                                Mon-Sun: {renderTime12(open_time)} - {renderTime12(close_time)} <br />
                                last entry {renderTime12(last_entry)} <br />
                                Closed Christmas Day.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright || constrain-width x-large no-pad">
                    <div className="footer-copyright-inner || constrain-width">
                        <p className="link">
                            <span>© {year} Tātaki Auckland Unlimited</span>
                            <Link to="/privacy-policy"> Copyright and privacy</Link>
                            <span className="link-spacer">|</span>
                            <Link to="/terms-and-conditions">Terms and conditions</Link>
                        </p>
                        <a href="https://www.aucklandunlimited.com/" title="View the Tātaki Auckland Unlimited website">
                            <img width="200" className="logo" src={AUCKLAND_UNLIMITED_LOGO} alt="Tātaki Auckland Unlimited" />
                        </a>
                    </div>
                    <WomenRefuge />
                </div>
            </div>
        </footer>
    );
}

export default Footer;
