export const INITIAL_STATE = {
    emailAddress: {
        value: '', // The value on the input field.
        touched: false // Whether the input was touched or is pristine.
    }
};


// Newsletter Signup Actions
export const SET_EMAIL_ADDRESS = 'rfa-maritime-website/newsletter-signup/SET_EMAIL_ADDRESS';
export const RESET_NEWSLETTER_SIGNUP = 'rfa-maritime-website/newsletter-signup/RESET_NEWSLETTER_SIGNUP';


// Newsletter Signup Action Creators
export const setEmailAddressAction = (emailAddress) => ({
    type: SET_EMAIL_ADDRESS,
    emailAddress
});

export const resetNewsletterSignupAction = () => ({
    type: RESET_NEWSLETTER_SIGNUP
});


// Newsletter Signup Reducer
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_EMAIL_ADDRESS:
            return setEmailAddress(state, action.emailAddress);
        case RESET_NEWSLETTER_SIGNUP:
            return INITIAL_STATE;
        default:
            return state;
    }
};


// Newsletter Signup Reducer Helpers
function setEmailAddress(state, emailAddress) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        emailAddress
    };
}
