import { convertUrlToIframeSource } from 'app/utilities/youtube';
import { dataLayerPush } from 'app/utilities/gtm';
import Icon from 'app/components/partials/icon';
import PaginationIndication from 'app/components/partials/pagination-indication';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import React, { forwardRef } from 'react';
import { renderTime12 } from '../../utilities/time';

class HomepageHeroCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = HomepageHeroCarousel.createInitialState();
        this.imageCarousel = undefined;
        this.subHeroTitleCarousel = undefined;
    }

    static createInitialState() {
        return {
            currentItem: 0
        };
    }

    // Lifecycle
    UNSAFE_componentWillReceiveProps(nextProps) { // eslint-disable-line react/no-deprecated
        if (this.imageCarousel && this.subHeroTitleCarousel && nextProps.data !== this.props.data) {
            this.imageCarousel.slickGoTo(0);
            this.subHeroTitleCarousel.slickGoTo(0);
            this.setState(HomepageHeroCarousel.createInitialState);
        }
    }


    // Handler
    handleNext() {
        const { data } = this.props;
        const { currentItem } = this.state;

        /* ↓ Tracking gallery scroll events */
        dataLayerPush({
            id: 'micro_conversion',
            type: 'engaging_content',
            action: 'Gallery Click',
            text: data[currentItem].title
        });
        /* ↑ Tracking gallery scroll events */

        this.imageCarousel.slickNext();
    }

    handlePrevious() {
        const { data } = this.props;
        const { currentItem } = this.state;

        /* ↓ Tracking gallery scroll events */
        dataLayerPush({
            id: 'micro_conversion',
            type: 'engaging_content',
            action: 'Gallery Click',
            text: data[currentItem].title
        });
        /* ↑ Tracking gallery scroll events */

        this.imageCarousel.slickPrev();
    }


    // Render
    renderHeroCarousel() {
        const { data } = this.props;

        return (
            <div className="homepage-hero-carousel" ref={this.props.innerRef}>
                <Slider
                    ref={(me) => { this.imageCarousel = me; }}
                    {...IMAGE_CAROUSEL_CONFIG}
                    beforeChange={(prevCurrentItem, nextCurrentItem) => {
                        this.setState({ currentItem: nextCurrentItem });
                        if (this.subHeroTitleCarousel) {
                            this.subHeroTitleCarousel.slickGoTo(nextCurrentItem);
                        }
                    }}
                >
                    {data.map(({ file, video }, index) => {
                        return (
                            <div key={index}>
                                <div
                                    className="homepage-hero-carousel-item"
                                    style={{ backgroundImage: `url(${file})` }}
                                >
                                    <span className="screen-reader-only-text">{`Carousel slide ${index + 1} image`}</span>
                                    {video && (
                                        <div className="homepage-hero-carousel-video-outer">
                                            <div className="homepage-hero-carousel-video">
                                                <iframe
                                                    className="homepage-hero-carousel-video-inner"
                                                    title={`Carousel slide ${index + 1} video`}
                                                    src={convertUrlToIframeSource(video, true)}
                                                    frameBorder="0"
                                                    allow="autoplay"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </Slider>
                {data.length > 1 &&
                    <button className="carousel-control previous" onClick={this.handlePrevious.bind(this)}>
                        <Icon name="dropdown" title="Previous" />
                    </button>
                }
                {data.length > 1 &&
                    <button className="carousel-control next" onClick={this.handleNext.bind(this)}>
                        <Icon name="dropdown" title="Next" />
                    </button>
                }
            </div>
        );
    }

    renderSubHeroContent() {
        const { data } = this.props;
        const { currentItem } = this.state;
        // eslint-disable-next-line camelcase
        const { open_time, close_time } = this.props.globalFields;

        return (
            <div className="homepage-sub-hero || constrain-width no-pad">
                <div className="homepage-sub-hero-slider-and-pagination">
                    <Slider
                        className="homepage-sub-hero-slider"
                        ref={(me) => { this.subHeroTitleCarousel = me; }}
                        {...SUB_HERO_TITLE_CAROUSEL_CONFIG}
                    >
                        {data.map(({ title, subtitle }, index) => {
                            return (
                                <a
                                    key={index}
                                    className="homepage-sub-hero-slider-slide"
                                    href={data[currentItem].link}
                                >
                                    <div className="homepage-sub-hero-slider-slide-inner">
                                        <div className="homepage-sub-hero-slider-slide-kia-ora || heading-5">{title}</div>
                                        <h1 className="homepage-sub-hero-slider-slide-title">{subtitle}</h1>
                                    </div>
                                </a>
                            );
                        })}
                    </Slider>
                    <div className="homepage-sub-hero-pagination">
                        <PaginationIndication
                            total={data.length}
                            currentIndex={currentItem}
                            fixedWidth={false}
                        />
                    </div>
                </div>
                <div className="homepage-sub-hero-opening-hours || heading-5">
                    <div>
                       Open {renderTime12(open_time)} - {renderTime12(close_time)}, free for Aucklanders
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <section className="constrain-width x-large no-pad">
                {this.renderHeroCarousel()}
                {this.renderSubHeroContent()}
            </section>
        );
    }
}

HomepageHeroCarousel.propTypes = {
    data: PropTypes.array.isRequired,
    globalFields: PropTypes.object.isRequired,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any })
    ]),
};

const IMAGE_CAROUSEL_CONFIG = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    draggable: true,
    infinite: true,
    pauseOnHover: true,
    speed: 400,
    swipe: true,
    arrows: false,
    adaptiveHeight: false,
    fade: false
};

const SUB_HERO_TITLE_CAROUSEL_CONFIG = {
    autoplay: false,
    dots: false,
    draggable: false,
    infinite: true,
    pauseOnHover: true,
    speed: 400,
    swipe: false,
    arrows: false,
    adaptiveHeight: false,
    fade: true
};

const HomepageHeroCarouselForwardingRef = forwardRef((props, ref) => <HomepageHeroCarousel innerRef={ref} {...props} />);

HomepageHeroCarouselForwardingRef.displayName = 'HomepageHeroCarousel';

export default HomepageHeroCarouselForwardingRef;
