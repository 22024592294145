// This needs to be kept in sync with the page parent options in the CMS.
const pageParents = [
    {
        name: 'What’s On',
        slug: 'whats-on'
    },
    {
        name: 'Visit',
        slug: 'visit'
    },
    {
        name: 'Sailings',
        slug: 'sailings'
    },
    {
        name: 'Collections',
        slug: 'collections',
    },
    {
        name: 'Learn',
        slug: 'learn'
    },
    {
        name: 'About',
        slug: 'about'
    }
];

export function getParentNameFromSlug(parentSlug) { // eslint-disable-line require-jsdoc
    const pageParentSlugAndName = pageParents.find(({ slug }) => slug === parentSlug);
    if (pageParentSlugAndName) {
        return pageParentSlugAndName.name;
    }

    return parentSlug;
}

export default pageParents;
