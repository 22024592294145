import NewsletterSignupForm from 'app/components/partials/newsletter-signup-form';
import React, { Component } from 'react';

class NewsletterContentBlock extends Component {
    render() {
        return (
            <section className="newsletter-block || constrain-width no-pad || content-section">
                <div className="newsletter-block-inner">
                    <div className="newsletter-block-heading || heading-2">
                        Keep up with the latest news, events and sailing info.
                    </div>
                    <div className="newsletter-block-intro-and-form">
                        <p className="newsletter-block-intro">
                            Subscribe to our newsletter and find out what&apos;s happening behind the scenes at Maritime Museum
                        </p>
                        <div className="newsletter-block-form">
                            <NewsletterSignupForm
                                border={false}
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

NewsletterContentBlock.propTypes = {
};

export default NewsletterContentBlock;
