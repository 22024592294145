import Banner from 'app/components/partials/banner';
import ContentBlocks from 'app/components/content-blocks/index';
import EventDetails from 'app/components/partials/event-details';
import PropTypes from 'prop-types';
import React from 'react';
import renderEventStructuredData from 'app/utilities/render-event-structured-data';

function Event({ data }) { // eslint-disable-line require-jsdoc
    const {
        blocks,
        name: title,
        parent: parentSlug,
    } = data;

    return (
        <div className="content-page">
            <Banner
                title={title}
                parentSlug={parentSlug}
            />
            {renderEventStructuredData(data)}
            <EventDetails data={data} />
            <ContentBlocks data={blocks} />
        </div>
    );
}

Event.propTypes = {
    data: PropTypes.object.isRequired
};

export default Event;
