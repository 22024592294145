import formatContent from 'app/utilities/format-content';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

function ImageGallerySingle(props) { // eslint-disable-line require-jsdoc
    const { creditline, creditlineJsx, file, url, buttonText } = props.item;

    return (
        <section className="image-gallery-single || constrain-width">
            <div className="image-gallery-single-content || rte-content">
                {creditline && <div className='rich-editor-content' dangerouslySetInnerHTML={{ __html: formatContent(creditline, false) }}></div>}
                {creditlineJsx}
                {url && buttonText &&
                    <a href={url} className="text-link">
                        {buttonText}
                        <Icon name="right-arrow" />
                    </a>
                }
            </div>
            <div className="image-gallery-single-media || preserve-aspect-ratio" style={{ backgroundImage: `url(${file})` }}>
                <div className="preserve-aspect-ratio-media">
                    <img className="fixed-image" src={file} alt={file} />
                </div>
            </div>
        </section>
    );
}

ImageGallerySingle.propTypes = {
    item: PropTypes.object.isRequired
};

export default ImageGallerySingle;
