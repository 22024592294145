import React from 'react';

function Loader() { // eslint-disable-line require-jsdoc
    return (
        <div className="loader-container">
            <div className="loader" />
            <p className="loader-text">Loading...</p>
        </div>
    );
}

export default Loader;
