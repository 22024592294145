import Data from 'app/components/partials/data';
import PropTypes from 'prop-types';
import React from 'react';

function Button({ title, url }) {
    return (
        <Data title="Content Block: Button" data={{ title, url }} />
    );
}

Button.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
};

export default Button;
