import moment from 'moment';

import { THIS_WEEK } from 'config/whats-on';

export default (item, whatsOnTimeRange) => {
    if (item.type === 'sailing') {
        // Deal with sailings.

        if (item.data.schedule_blocks && item.data.schedule_blocks.length) {
            // The sailing has time slots defined in the CMS.

            const sailingIsoWeekdaysSet = new Set(item.data.schedule_blocks.map(({ isoWeekday }) => Number(isoWeekday)));
            const sailingIsoWeekdaysArray = [...sailingIsoWeekdaysSet];
            sailingIsoWeekdaysArray.sort();

            if (sailingIsoWeekdaysArray.length === 1) {
                // The sailing is on only one day a week. Return "Mondays", "Tuesdays", etc.
                return `${moment().isoWeekday(sailingIsoWeekdaysArray[0]).format('dddd')}s`;
            }

            // Contiguous ranges of weekdays
            const sailingIsoWeekdayRanges = [];
            let currentRange = [];
            sailingIsoWeekdaysArray.forEach((weekday) => {
                if (currentRange.length) {
                    if (weekday === currentRange[currentRange.length - 1] + 1) {
                        // The current weekday is the extension of the current range
                        currentRange.push(weekday);
                    } else {
                        // The current weekday starts a new range
                        sailingIsoWeekdayRanges.push(currentRange);
                        currentRange = [weekday];
                    }
                } else {
                    currentRange = [weekday];
                }
            });
            if (currentRange.length) {
                sailingIsoWeekdayRanges.push(currentRange);
            }

            // Transform ranges into strings and join.
            return sailingIsoWeekdayRanges.map((range) => {
                const startDay = range[0];
                const endDay = range.pop();
                if (startDay === endDay) {
                    return moment().isoWeekday(startDay).format('ddd');
                }

                return `${moment().isoWeekday(startDay).format('ddd')} – ${moment().isoWeekday(endDay).format('ddd')}`;
            }).join(', ');
        }

        // The sailing does not have any time slots defined in the CMS.
        return '';
    }

    // Deal with events and exhibitions.
    if (item.data.start_date && item.data.end_date) {
        // The event or exhibition is not permanent.

        const start = moment(item.data.start_date);
        const end = moment(item.data.end_date);
        const today = moment();

        if (start.isSame(end, 'day')) {
            // This is the one-day event or one-day exhibition.

            if (start.isSame(today, 'day')) {
                // This is the event that is on today only.
                return 'Today';
            }

            if (whatsOnTimeRange === THIS_WEEK) {
                // Render the day as "Monday", "Tuesday", etc.
                return start.format('dddd');
            }

            // Render the day as "21 Feb", etc.
            return start.format('D MMM');
        }

        // Otherwise the event or exhibition spans multiple days.
        if (start.isSame(end, 'month')) {
            // Render the range as "21–27 Feb", etc.
            return `${start.format('D')}–${end.format('D MMM')}`;
        }

        // Render the range as "21 Feb – 17 Mar", etc.
        return `${start.format('D MMM')} – ${end.format('D MMM')}`;
    }

    // If it's an exhibition and either the start of end date are not present, show "Permanent"
    if (item.type === 'exhibition') {
        return 'Permanent';
    }

    return '';
};
