/**
 * Extract a Youtube video id from a video url
 *
 * @param  {string} url Youtube video URL
 *
 * @return {string} Youtube video ID
 */
export const getVideoIdFromUrl = (url) => {
    const match = url.match(/youtu\.be\/(.{11})/) || url.match(/youtube\.com\/watch\?v=(.{11})/);

    if (match) {
        return match[1];
    }

    return null;
};


/**
 * Convert a Youtube video url to an embeddable url for use in iframes
 *
 * @param  {string} url               Youtube video URL
 * @param  {string} isBackgroundVideo Whether the video is to be played in the background
 *
 * @return {string} Youtube video embed URL
 */
export const convertUrlToIframeSource = (url, isBackgroundVideo = false) => {
    const id = getVideoIdFromUrl(url);

    if (isBackgroundVideo) {
        return `https://www.youtube.com/embed/${id}?autoplay=1&mute=1&controls=0&loop=1&playlist=${id}&modestbranding=1&rel=0&showinfo=0`;
    }

    return `https://www.youtube.com/embed/${id}?rel=0&controls=1&showinfo=0`;
};
