import classnames from 'classnames';
import Icon from './icon';
import PropTypes from 'prop-types';
import React from 'react';
import { HEADER_CTA, HEADER_NAVIGATION } from 'config/navigation.js';
import { Link, NavLink } from 'react-router-dom'; // eslint-disable-line

class Navigation extends React.Component {
    // Handlers
    handleToggleChildNavigation(index) {
        const { itemOpen, updateOpenItem } = this.props;

        updateOpenItem(itemOpen === index ? null : index);
    }


    // Render
    renderNavigationItem({ children, label, link }, index) {
        const itemClass = classnames('navigation-item', {
            'is-active': this.props.itemOpen === index
        });


        return (
            <div className={itemClass} key={index}>
                <NavLink
                    className={({ isActive }) => classnames('navigation-link', 'heading-5', {
                        'is-active': isActive
                    })}
                    to={link}
                >
                    {label}
                </NavLink>
                {children && (
                    <button
                        type="button"
                        className="sub-navigation-toggle"
                        onClick={this.handleToggleChildNavigation.bind(this, index)}>
                        {this.props.itemOpen === index ? (
                            <Icon name="minus" title="Close subpages" />
                        ) : (
                            <Icon name="plus" title="Open subpages" />
                        )}
                    </button>
                )}
                {children && (
                    <nav className="sub-navigation">
                        <NavLink
                            className={({ isActive }) => classnames(
                                'sub-navigation-item',
                                'sub-navigation-item-overlay',
                                'heading-5',
                                {
                                    'is-active': isActive
                                }
                            )}
                            to={link}
                        >
                            {label}
                        </NavLink>
                        <div className="sub-navigation-items">
                            {children.map(({ label, link }, subIndex) => {
                                // Render external links
                                if (link.startsWith('http')) {
                                    return (
                                        <a className="sub-navigation-item || heading-5" href={link} key={`${index}-${subIndex}`}>{label}</a>
                                    );
                                }

                                return (
                                    <NavLink
                                        className={({ isActive }) => classnames('sub-navigation-item', 'heading-5', {
                                            'is-active': isActive
                                        })}
                                        to={link}
                                        key={`${index}-${subIndex}`}>
                                        {label}
                                    </NavLink>
                                );
                            })}
                        </div>
                    </nav>
                )}
            </div>
        );
    }

    render() {
        const { isActive, searchDialogIsActive } = this.props;

        const navigationClass = classnames('navigation-bar', {
            'is-active': isActive,
            'is-hidden': searchDialogIsActive
        });


        return (
            <div className={navigationClass}>
                <div className="navigation-bar-inner">
                    <nav className="navigation">
                        {HEADER_NAVIGATION.map((item, index) => {
                            return this.renderNavigationItem(item, index);
                        })}

                        <div className="navigation-item navigation-cta-wrapper">
                            <a
                                className="navigation-cta || button alternative inverse"
                                href={HEADER_CTA.link}
                                /* ↓ Tracking attributes */
                                {...HEADER_CTA.trackingAttributes}
                                /* ↑ Tracking attributes */
                            >{HEADER_CTA.label}</a>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}

Navigation.propTypes = {
    isActive: PropTypes.bool.isRequired,
    itemOpen: PropTypes.number, // Not required so we can pass null
    searchDialogIsActive: PropTypes.bool.isRequired,
    updateOpenItem: PropTypes.func.isRequired
};

export default Navigation;
