import { dataLayerPush } from 'app/utilities/gtm';
import Icon from 'app/components/partials/icon';
import PaginationIndication from 'app/components/partials/pagination-indication';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import React, { Component } from 'react';

class Review extends Component { // eslint-disable-line require-jsdoc
    constructor(props) {
        super(props);
        this.state = Review.createInitialState();
        this.carousel = undefined;
        this.renderBlockquote = this.renderBlockquote.bind(this);
    }

    static createInitialState() {
        return {
            currentItem: 0
        };
    }

    // Lifecycle
    UNSAFE_componentWillReceiveProps(nextProps) { // eslint-disable-line react/no-deprecated
        if (this.carousel && nextProps.data !== this.props.data) {
            this.carousel.slickGoTo(0);
            this.setState(Review.createInitialState);
        }
    }

    // Handler
    handleNext() {
        const { data } = this.props;
        const { currentItem } = this.state;

        /* ↓ Tracking gallery scroll events */
        dataLayerPush({
            id: 'micro_conversion',
            type: 'engaging_content',
            action: 'Review Click',
            text: data[currentItem].quote
        });
        /* ↑ Tracking gallery scroll events */

        this.carousel.slickNext();
    }

    handlePrevious() {
        const { data } = this.props;
        const { currentItem } = this.state;

        /* ↓ Tracking gallery scroll events */
        dataLayerPush({
            id: 'micro_conversion',
            type: 'engaging_content',
            action: 'Review Click',
            text: data[currentItem].quote
        });
        /* ↑ Tracking gallery scroll events */

        this.carousel.slickPrev();
    }

    // Render
    renderStars(stars) {
        const starsJsx = [];
        for (let i = 0; i < stars; ++i) {
            starsJsx.push(
                <Icon
                    key={i}
                    className="pagebreak-quote-content-icon"
                    name="star"
                />
            );
        }

        return starsJsx;
    }

    renderCarousel() {
        const { data } = this.props;

        return (
            <Slider
                className=""
                ref={(me) => { this.carousel = me; }}
                {...CAROUSEL_CONFIG}
                beforeChange={(prevCurrentItem, nextCurrentItem) => {
                    this.setState({ currentItem: nextCurrentItem });
                }}
            >
                {data.map(this.renderBlockquote)}
            </Slider>
        );
    }

    renderBlockquote(item, index) {
        const {
            stars,
            quote,
            source: cite,
            source_url: url
        } = item;

        return (
            <blockquote className="pagebreak-quote-content" key={index}>
                <div className="pagebreak-quote-content-text">
                    <p>{quote}</p>
                </div>
                <div title={`${stars} stars`}>{this.renderStars(Number(stars))}</div>
                <em>{url ? <a href={url}>{cite}</a> : `${cite}`}</em>
            </blockquote>
        );
    }

    render() {
        return (
            <section className="pagebreak-quote || content-section">
                <div className="pagebreak-quote-inner || constrain-width no-pad">
                    {this.renderCarousel()}
                    {this.props.data.length > 1 &&
                        <div className="pagebreak-quote-button-group || button-group">
                            <button className="previous || button alternative" onClick={this.handlePrevious.bind(this)}>
                                <Icon name="right" title="Previous" />
                            </button>
                            <button className="button alternative" onClick={this.handleNext.bind(this)}>
                                <Icon name="right" title="Next" />
                            </button>
                        </div>
                    }
                    <div className="pagebreak-quote-pagination || constrain-width">
                        <PaginationIndication
                            total={this.props.data.length}
                            currentIndex={this.state.currentItem}
                            fixedWidth
                        />
                    </div>
                </div>
            </section>
        );
    }
}

Review.propTypes = {
    data: PropTypes.array.isRequired
};

const CAROUSEL_CONFIG = {
    dots: false,
    draggable: true,
    infinite: true,
    speed: 200,
    swipe: true,
    arrows: false,
    slidesToShow: 1,
};

export default Review;
