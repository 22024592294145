import PropTypes from 'prop-types';
import React from 'react';
import WhatsOn from '../partials/whats-on';

const EventSet = ({ items, rows, title }) => {
    // Transform items into WhatsOn items format
    const transformedItems = items.map((item) => ({
        ...item,
        attributes: item.data,
    }));

    return (
        <section className="tile-block || whats-on || content-section">
            {title && (
                <header className="tile-block-title || constrain-width">
                    <h2>{title}</h2>
                </header>
            )}
            <WhatsOn items={transformedItems} rows={rows} />
        </section>
    );
};

EventSet.defaultProps = {
    rows: 1
};

EventSet.propTypes = {
    items: PropTypes.array.isRequired,
    title: PropTypes.string,
    rows: PropTypes.string
};

export default EventSet;
