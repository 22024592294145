import formatContent from '../../utilities/format-content';
import Icon from 'app/components/partials/icon';
import PaginationIndication from 'app/components/partials/pagination-indication';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';

const DESKTOP_WIDTH = 1000;

class ImageGalleryDouble extends React.Component {
    constructor(props) {
        super(props);
        this.state = ImageGalleryDouble.createInitialState();
        this.imageGallery = undefined;

        this.handlePrevious = this.handlePrevious.bind(this);
        this.handleNext = this.handleNext.bind(this);
    }

    static createInitialState() {
        return {
            currentIndex: 0,
            desktopView: false
        };
    }

    get slides() {
        const { items } = this.props;

        // Reformat items into slides of two
        const slides = [];
        let slideIndex = 0;

        items.map((item, index) => {
            // Start count at 1 not 0
            const objectKey = (index + 1) % 2 ? 'primary' : 'secondary';

            // Add to next available
            if (objectKey === 'primary') {
                slides.push({
                    primary: null,
                    secondary: null
                });
            }

            slides[slideIndex][objectKey] = item;

            // Next slide
            if (objectKey === 'secondary') slideIndex++;
        });

        return slides;
    }

    get content() {
        const { items } = this.props;

        return items[0];
    }


    // Lifecycle
    componentDidMount() {
        this.setState({ desktopView: window && window.innerWidth > DESKTOP_WIDTH });
    }

    UNSAFE_componentWillReceiveProps(nextProps) { // eslint-disable-line react/no-deprecated
        if (this.imageGallery && nextProps.items !== this.props.items) {
            this.imageGallery.slickGoTo(0);

            this.setState(ImageGalleryDouble.createInitialState);
        }
    }


    // Handler
    handleNext() {
        this.imageGallery.slickNext();
    }

    handlePrevious() {
        this.imageGallery.slickPrev();
    }


    // Render
    renderNavigationButtons() {
        return (
            <div className="image-gallery-double-buttons || button-group">
                <button className="previous || button alternative" onClick={this.handlePrevious}>
                    <Icon name="right" title="Previous" />
                </button>
                <button className="button alternative" onClick={this.handleNext}>
                    <Icon name="right" title="Next" />
                </button>
            </div>
        );
    }

    renderDesktopGallery() {
        return (
            <div className="image-gallery-double-media desktop">
                {this.slides.length > 1 && this.renderNavigationButtons()}
                <Slider
                    ref={(me) => { this.imageGallery = me; }}
                    {...IMAGE_CAROUSEL_CONFIG}
                    beforeChange={(previousIndex, currentIndex) => {
                        this.setState({ currentIndex });
                    }}
                >
                    {this.slides.map(({ primary, secondary }, index) => {
                        return (
                            <div key={index} className="image-gallery-double-media-inner">
                                <div className="primary || preserve-aspect-ratio" style={{ backgroundImage: `url(${primary.file})` }}>
                                    <div className="preserve-aspect-ratio-media">
                                        <img className="fixed-image" src={primary.file} alt={primary.file} />
                                    </div>
                                </div>
                                <div className="secondary || preserve-aspect-ratio" style={{ backgroundImage: `url(${secondary.file})` }}>
                                    <div className="preserve-aspect-ratio-media">
                                        <img className="fixed-image" src={secondary.file} alt={secondary.file} />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        );
    }

    renderMobileGallery() {
        const { items } = this.props;

        return (
            <div className="image-gallery-double-media mobile">
                {items.length > 1 && this.renderNavigationButtons()}
                <Slider
                    ref={(me) => { this.imageGallery = me; }}
                    {...IMAGE_CAROUSEL_CONFIG}
                    beforeChange={(previousIndex, currentIndex) => {
                        this.setState({ currentIndex });
                    }}
                >
                    {items.map(({ file }, index) => {
                        return (
                            <div key={index} className="image-gallery-double-media-inner">
                                <div className="primary || preserve-aspect-ratio" style={{ backgroundImage: `url(${file})` }}>
                                    <div className="preserve-aspect-ratio-media">
                                        <img className="fixed-image" src={file} alt={file} />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        );
    }

    render() {
        const { currentIndex, desktopView } = this.state;
        const { items } = this.props;
        const { creditline, creditlineJsx, buttonText, link } = this.content;
        const slidesLength = desktopView ? this.slides.length : items.length;

        return (
            <section className="image-gallery-double || content-section || constrain-width no-pad">
                <div className="image-gallery-double-pagination">
                    <PaginationIndication
                        total={slidesLength}
                        currentIndex={currentIndex}
                        fixedWidth
                    />
                </div>
                <div className="image-gallery-double-inner">
                    <div className="image-gallery-double-content || rte-content">
                        {creditline && <div className='rich-editor-content' dangerouslySetInnerHTML={{ __html: formatContent(creditline, false) }}></div>}
                        {creditlineJsx}
                        {link && buttonText &&
                            <a href={link} className="text-link">
                                {buttonText}
                                <Icon name="right-arrow" />
                            </a>
                        }
                    </div>
                </div>
                {desktopView ? this.renderDesktopGallery() : this.renderMobileGallery()}
            </section>
        );
    }
}

ImageGalleryDouble.propTypes = {
    items: PropTypes.array.isRequired
};

const IMAGE_CAROUSEL_CONFIG = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    draggable: false,
    infinite: true,
    pauseOnHover: true,
    speed: 300,
    swipe: true,
    arrows: false,
    responsive: [
        {
            breakpoint: 1000,
            settings: {
                autoplay: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                draggable: true,
                infinite: false,
                centerPadding: '25px',
                speed: 200,
            }
        },
        {
            breakpoint: 600,
            settings: {
                autoplay: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                draggable: true,
                infinite: false,
                centerPadding: '15px',
                speed: 200,
            }
        }
    ]
};

export default ImageGalleryDouble;
