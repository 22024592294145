import classnames from 'classnames';
import formatContent from 'app/utilities/format-content';
import PropTypes from 'prop-types';
import React from 'react';

function TextContent(props) {
    const { aside, content } = props.data;

    return (
        <section className="constrain-width || content-section || text-content-block">
            <div className={classnames('content-section-inner', { 'has-aside': aside })}>
                <main
                    className="content-section-main || rte-content || rich-editor-content"
                    dangerouslySetInnerHTML={{ __html: formatContent(content) }}
                />
                {aside && (
                    <aside
                        className="content-section-aside || rte-content || rich-editor-content"
                        dangerouslySetInnerHTML={{ __html: formatContent(aside) }}
                    />
                )}
            </div>
        </section>
    );
}

TextContent.propTypes = {
    data: PropTypes.object.isRequired
};

export default TextContent;
