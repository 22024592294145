import Helmet from 'react-helmet';
import ImageGallerySingle from 'app/components/partials/image-gallery-single';
import NotFoundImage from 'static/images/not-found.png';
import React from 'react';

function NotFound() { // eslint-disable-line require-jsdoc
    const item = {
        creditline: '<h1>Something went wrong.</h1><p>Sorry, we have recently re-organised the site and the page you were looking for appears to have been moved, deleted or does not exist.</p>',
        file: NotFoundImage,
        url: 'https://maritimemuseum.co.nz',
        buttonText: 'Back to home page'
    };

    return (
        <main className="page" role="main">
            <Helmet>
                <title>404 Page not found</title>
            </Helmet>
            <div className="not-found">
                <ImageGallerySingle item={item}/>
            </div>
        </main>
    );
}

export default NotFound;
