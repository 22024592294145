import classnames from 'classnames';
import { clientEnv } from 'config/env';
import Dropdown from './dropdown';
import fieldTypes from 'config/field-types';
import PropTypes from 'prop-types';
import React from 'react';
import renderCaptcha from 'app/utilities/render-captcha';
import { formIsValid, validate } from 'app/utilities/form-validation';

class ContactUsForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            captchaToken: null
        };
        this.abandonCaptcha = undefined;
        this.resetCaptcha = undefined;
    }

    // Life cycle
    componentDidMount() {
        const { abandon, reset } = renderCaptcha(
            this.captchaElement, // Mounting point for the capcha.
            clientEnv.RECAPTCHA_KEY, // reCAPTCHA sitekey
            (captchaToken) => {
                this.setState({ captchaToken });
            } // reCAPTCHA verify callback
        );

        this.abandonCaptcha = abandon;
        this.resetCaptcha = reset;
    }

    componentDidUpdate(prevProps) { // eslint-disable-line react/no-deprecated
        if (prevProps.submitted && !this.props.submitted && this.resetCaptcha) {
            this.setState({
                captchaToken: null
            }, this.resetCaptcha);
        }
    }

    UNSAFE_componentWillUnmount() {
        // Make sure the app stops trying to render the captcha if it still keeps trying.
        if (this.abandonCaptcha) {
            this.abandonCaptcha();
        }
    }

    // Handlers
    handleSubmit(event) {
        event.preventDefault();

        const { formFields, submitForm, submitted, submittedSuccessfully } = this.props;

        if (!submitted || !submittedSuccessfully) {
            submitForm(formFields.reduce((acc, { name, value }) => {
                acc[name] = value;

                return acc;
            }, {}), this.state.captchaToken);
        }
    }


    // Render
    renderFieldInner(field, setField, validationData) {
        const { required, placeholder, fieldType, value } = field;

        switch (fieldType) {
            case fieldTypes.SELECT:
                return (
                    <Dropdown
                        options={field.options}
                        value={value}
                        placeholder={placeholder}
                        isValid={validationData.valid}
                        required={required}
                        setValue={(nextValue) => setField({
                            ...field,
                            value: nextValue,
                            touched: true
                        })}
                        setTouched={(nextTouched) => setField({
                            ...field,
                            touched: nextTouched
                        })}
                    />
                );
            case fieldTypes.TEXTAREA:
                return (
                    <textarea
                        className={classnames('form-control || textarea', { 'is-invalid': !validationData.valid })}
                        placeholder={placeholder}
                        required={required}
                        value={value}
                        onChange={(event) => {
                            setField({
                                ...field,
                                value: event.target.value,
                                touched: true
                            });
                        }}
                        onBlur={() => {
                            setField({
                                ...field,
                                touched: true
                            });
                        }}
                    />
                );
            case fieldTypes.INPUT:
            default:
                return (
                    <input
                        className={classnames('form-control || input', { 'is-invalid': !validationData.valid })}
                        type={field.type}
                        placeholder={placeholder}
                        required={required}
                        value={value}
                        onChange={(event) => {
                            setField({
                                ...field,
                                value: event.target.value,
                                touched: true
                            });
                        }}
                        onBlur={() => {
                            setField({
                                ...field,
                                touched: true
                            });
                        }}
                    />
                );
        }
    }

    renderField(field, fieldIndex, setField) {
        const { label, required, validatorType, value, touched } = field;
        const validationData = validate(validatorType, value, touched);

        return (
            <label // eslint-disable-line jsx-a11y/label-has-for
                key={fieldIndex}
                className="form-group"
            >
                <div className="form-label">{label}{required && '*'}</div>
                {this.renderFieldInner(field, setField, validationData)}
                <span className="form-control-alert danger">{!validationData.valid && validationData.message}</span>
            </label>
        );
    }

    renderFields() {
        const { formFields, setFormFields } = this.props;

        return (
            <div>
                {formFields.map((formField, formFieldIndex) => this.renderField(
                    formField,
                    formFieldIndex,
                    (nextFormField) => {
                        const nextFormFields = [...formFields];
                        nextFormFields[formFieldIndex] = nextFormField;
                        setFormFields(nextFormFields);
                    }
                ))}
            </div>
        );
    }

    renderSubmitButton() {
        const { formFields } = this.props;

        const invalid = !formIsValid(formFields);

        return (
            <button
                key="button"
                className="button primary large"
                type="submit"
                disabled={invalid || !this.state.captchaToken}
            >
                Send
            </button>
        );
    }

    renderSubmitSection() {
        const { isBusy, submitted, submittedSuccessfully } = this.props;

        if (isBusy) {
            return (
                <button
                    type="button"
                    className="button primary large"
                    disabled
                >
                    Sending...
                </button>
            );
        }

        if (submittedSuccessfully) {
            return (
                <button
                    type="button"
                    className="button primary large"
                    disabled
                >Sent</button>
            );
        }

        if (submitted) {
            return [
                <p key="p">Sorry, we couldn’t send your message. Please try again.</p>,
                this.renderSubmitButton()
            ];
        }

        return this.renderSubmitButton();
    }

    render() {
        return (
            <section className="constrain-width || content-section || text-content-block">
                <div className="content-section-inner">
                    <main
                        className="content-section-main || rte-content"
                    >
                        <h2 className="primary">Get in touch</h2>
                        <p>We would love to hear from you and see how we can help. Get in touch by sending us a message using the form below.</p>
                        <form
                            className="contact-us-form || form"
                            noValidate={true}
                            action="#"
                            onSubmit={this.handleSubmit.bind(this)}
                        >
                            {this.renderFields()}
                            <div className="g-recaptcha" ref={(me) => { this.captchaElement = me; }} />
                            <div className="contact-us-form-actions">
                                {this.renderSubmitSection()}
                            </div>
                        </form>
                    </main>
                </div>
            </section>
        );
    }
}


ContactUsForm.propTypes = {
    formFields: PropTypes.array.isRequired,
    setFormFields: PropTypes.func.isRequired,
    isBusy: PropTypes.bool.isRequired,
    submitForm: PropTypes.func.isRequired,
    submitted: PropTypes.bool.isRequired,
    submittedSuccessfully: PropTypes.bool
};

export default ContactUsForm;
