import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { mergeMap  } from 'rxjs/operators';
// // Uncomment this to test with mock data.
// import MOCK_DATA from 'config/mock-data';
import { selectWhatsOnResultsFromCache } from 'app/selectors/whats-on';
import { combineEpics, ofType } from 'redux-observable';
import { THIS_WEEK, whatsOnRequestBody } from 'config/whats-on';

export const INITIAL_STATE = {
    cache: [],
    whatsOnPageTimeRange: THIS_WEEK
};

// Actions
export const INITIATE_WHATS_ON_FETCH = 'rfa-maritime-website/search/INITIATE_WHATS_ON_FETCH';
export const SET_WHATS_ON_PAGE_TIME_RANGE = 'rfa-maritime-website/search/SET_WHATS_ON_PAGE_TIME_RANGE';
export const WHATS_ON_FETCH_SUCCESS = 'rfa-maritime-website/search/WHATS_ON_FETCH_SUCCESS';

// Action Creators
export const initiateWhatsOnFetchAction = (timeRange, entityTypes, comingUp, isFeatured) => ({
    type: INITIATE_WHATS_ON_FETCH,
    timeRange,
    entityTypes,
    comingUp,
    isFeatured
});

export const setWhatsOnPageTimeRangeAction = (timeRange) => ({
    type: SET_WHATS_ON_PAGE_TIME_RANGE,
    timeRange
});


export const whatsOnFetchSuccessAction = (timeRange, entityTypes, results, comingUp, isFeatured) => ({
    type: WHATS_ON_FETCH_SUCCESS,
    payload: {
        timeRange,
        entityTypes,
        results,
        comingUp,
        isFeatured
    }
});

// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_WHATS_ON_PAGE_TIME_RANGE:
            return setWhatsOnPageTimeRange(state, action.timeRange);
        case WHATS_ON_FETCH_SUCCESS:
            return whatsOnFetchSuccess(state, action.payload);
        default:
            return state;
    }
};

function setWhatsOnPageTimeRange(state, timeRange) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        timeRange
    };
}

function whatsOnFetchSuccess(state, { timeRange, entityTypes, results, comingUp, isFeatured }) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        cache: [
            ...state.cache,
            {
                timeRange,
                entityTypes,
                results,
                comingUp,
                isFeatured
            }
        ]
    };
}

// Epic creator
export const createWhatsOnEpic = (whatsOnEndpoint, formatResults, whatsOnFetchSuccessAction) => {
    return (action$, state$) => action$.pipe(
        ofType(INITIATE_WHATS_ON_FETCH),
        mergeMap(({ timeRange, entityTypes, comingUp, isFeatured }) => {
            // Check if the results already exist in the cache.
            if (selectWhatsOnResultsFromCache(state$.value, timeRange, entityTypes, comingUp, isFeatured)) {
                return [];
            }

            // // Uncomment this to test with mock data.
            // return new Promise((resolve) => {
            //     setTimeout(() => {
            //         resolve(whatsOnFetchSuccessAction(timeRange, entityTypes, MOCK_DATA.WHATS_ON_PROPS.items));
            //     }, 7000);
            // });

            return fetch(whatsOnEndpoint, null, {
                method: 'POST',
                body: JSON.stringify(whatsOnRequestBody(timeRange, entityTypes, comingUp, isFeatured))
            })
                .then((response) => whatsOnFetchSuccessAction(timeRange, entityTypes, formatResults(response), comingUp, isFeatured))
                .catch(() => []);
        })
    );
};

// Epics
const whatsOnEpic = createWhatsOnEpic(
    ENDPOINTS.WHATS_ON,
    formatResults,
    whatsOnFetchSuccessAction
);

// Helpers
function formatResults(response) { // eslint-disable-line require-jsdoc
    if (response && response.data && response.data.length) {
        return response.data;
    }

    return [];
}

export const epics = combineEpics(whatsOnEpic);
